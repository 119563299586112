import React, { useEffect, useRef, useState } from 'react';

const FhemComponent = () => {
    const [content, setContent] = useState('');
    const contentRef = useRef(null);

    // Fetch data from the proxy endpoint
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/fhem?room=Unsorted');  // ✅ Corrected API endpoint
                if (!response.ok) {
                    throw new Error(`Failed to fetch data. Status: ${response.status}`);
                }
                const data = await response.text();  // Assuming the response is HTML
                setContent(data);
            } catch (error) {
                console.error('Error fetching FHEM data:', error);
                setContent(`<p style="color: red;">Error fetching data: ${error.message}</p>`);
            }
        };

        fetchData();
    }, []);

    // Scroll to the bottom of the page when content is updated
    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
    }, [content]);

    return (
        <div
            ref={contentRef}
            style={{
                height: '100vh',
                overflowY: 'auto',
                padding: '10px',
                border: '1px solid #ccc',
            }}
            dangerouslySetInnerHTML={{ __html: content }}
        />
    );
};

export default FhemComponent;