import React, { createContext, useState, useContext, useRef } from 'react';
import DogModel from '../ObjectModels/DogModel.mjs';
import { makeProfilbildApiCall, makeGalerieApiCall, updateDogInDatabase, reloadDogFromDatabase, deleteDogInDatabase } from '../ObjectModels/DogModelAPI.mjs';
import { checkCancellation, cancelRequest } from '../Utils/clientCancelUtils.mjs';
import logger from '../Utils/clientLogger.mjs';

const DogContext = createContext();
const useDogContext = () => useContext(DogContext);

const newDogObject = () => { return new DogModel() };

const DogProvider = ({ children, dogData }) => {
	const [dogContextObject, setDog] = useState(dogData || new DogModel());
	const profilbildFetchRequest = useRef(null);
    const galerieFetchRequest = useRef(null);
 
	const updateDog = (newDog) => {
		setDog((prevDog) => {
			// If newDog is null or undefined, return prevDog or null
			if (!newDog) return prevDog || null;
		
			// Create a new DogModel, merging prevDog and newDog
			const returnDog = new DogModel({
				...prevDog, // Copy properties from prevDog
				...newDog,  // Override with properties from newDog
				galerie: newDog.galerie !== undefined ? newDog.galerie : prevDog?.galerie || [], // Copy galerie if newDog doesn't have it
			});
	
			logger.debug(returnDog); // Log the updated dog object
			return returnDog;
		});
	};

	const refreshDog = async (dogId) => {
		try {
			const refreshedDog = await reloadDogFromDatabase(dogId);
			setDog(refreshedDog);
		} catch (error) {
			if (error.response && error.response.status === 404) {
				logger.debug(`Dog to refresh not found:`, { error: error.message });
			}
			if (error.response && error.response.status === 499) {
				logger.debug(`refresh in context cancelled:`, { error: error.message });
			}
			else {
				logger.error("Other Error occurred:", { error: error.message });
			}
		}
	};

	const removeDog = async (dogId) => {
		try {
			await deleteDogInDatabase(dogId);
			setDog(new DogModel());
		} catch (error) {
			if (error.response && error.response.status === 404) {
				logger.debug(`Dog to delete not found:`, { error: error.message });
			}
			if (error.response && error.response.status === 499) {
				logger.debug(`Delete in Context cancelled:`, { error: error.message });
			}
			else {
				logger.error("Other Error occurred:", { error: error.message });
			}
		}
	};

	const updateDogInDb = async (dogData) => {
		try {
			const updatedDog = await updateDogInDatabase(dogData);
			setDog(updatedDog);
		} catch (error) {
			if (error.response && error.response.status === 404) {
				logger.debug(`Dog to update not found:`, { error: error.message });
			}
			if (error.response && error.response.status === 499) {
				logger.debug(`Update in Context cancelled:`, { error: error.message });
			}
			else {
				logger.error("Other Error occurred:", { error: error.message });
			}
		}
	};

	const setProfilbild = (newProfilbild) => {
		setDog((prevDog) => new DogModel({ ...prevDog, profilbild: newProfilbild }));
	};

	const setGalerie = (newGalerie) => {
		setDog((prevDog) => new DogModel({ ...prevDog, galerie: newGalerie ?? [] }));
	};
	
	const getProfilbild = async () => {
		try {
			if (dogContextObject.id < 0 || profilbildFetchRequest.current) return;
			profilbildFetchRequest.current = `profilimage-${dogContextObject.id}`;	
			const image = await makeProfilbildApiCall(dogContextObject.id, profilbildFetchRequest.current);
			setProfilbild(image);
		} catch (error) {
			if (error.response && error.response.status === 404) {
				logger.debug(`No Profilbild found for dog:`, { requestId: profilbildFetchRequest.current, error: error.message });
			}
			if (error.response && error.response.status === 499) {
				logger.debug(`Profilbild fetch request was cancelled on server side:`, { requestId: profilbildFetchRequest.current, error: error.message });
			}
			else {
				logger.error(`something unexpected happened in profilbild fetch:`, { requestId: profilbildFetchRequest.current, error: error.message });
				cancelProfilbildRequest();
			}			
		} finally {
			//clearInterval(cancellationInterval);
		}
	};

	const getGalerie = async () => {
		let cancellationInterval;
		try {
			if (dogContextObject.id < 0 || galerieFetchRequest.current) return;
			let offset = 0;
			let limit = 5;
			galerieFetchRequest.current = `fetch-galerie-${dogContextObject.id}`

			const cleanup = () => {
				console.log(`Request ${galerieFetchRequest.current} has been Cleaned up.`);
			};
	
			const onCancel = async (error) => {
				continueFetching = false;
			};
	
			cancellationInterval = checkCancellation(galerieFetchRequest.current, cleanup, onCancel);

			
			let continueFetching = true;
			const ids = dogContextObject.galerie.map(item => item.id);
			while (continueFetching) {
				const galerieBatch = await makeGalerieApiCall(dogContextObject.id, ids, offset, limit, galerieFetchRequest.current)
	
				if (galerieBatch.length < limit) {
					continueFetching = false;
					if (galerieBatch.length === 0) break;
				}
	
				const data = galerieBatch.map((item, index) => ({
					...item,
					galerieIndex: dogContextObject.galerie.length + index
				}));
	
				const updatedGalerie = [...dogContextObject.galerie, ...data];

				// Pass the combined array to setGalerie
				await setGalerie(updatedGalerie);
				offset += limit; // Update the offset for the next batch
				await new Promise(resolve => setTimeout(resolve, 800));
			}
		} catch (error) {
			if (error.response && error.response.status === 404) {
				logger.debug(`No Galerie images found in database:`, error.message);
			}
			if (error.response && error.response.status === 499) {
				logger.debug(`Galerie fetch request was cancelled on server side:`, error.message);
			}
			else {
				logger.error(`something unexpected happened:`, { error });
				cancelGalerieRequest();
			}
		}
		finally{
			clearInterval(cancellationInterval);
		}
	};

    const cancelProfilbildRequest = async () => {
        if (profilbildFetchRequest.current) {
			await cancelRequest(profilbildFetchRequest.current);
            profilbildFetchRequest.current = null;
        }
    };

    const cancelGalerieRequest = async () => {
        if (galerieFetchRequest.current) {
            await cancelRequest(galerieFetchRequest.current);
            galerieFetchRequest.current = null;
        }
    };
  
	const dogState = { 
		dogContextObject, 
		updateDog, 
		setProfilbild, 
		getProfilbild, 
		cancelProfilbildRequest, 
		setGalerie, 
		getGalerie, 
		cancelGalerieRequest, 
		updateDogInDb,
		removeDog, 
		refreshDog
	};
    
	return (
		<DogContext.Provider value={dogState}>
			{children}
		</DogContext.Provider>
	);
};

export {DogProvider, useDogContext, newDogObject}