import log from 'loglevel';

// Helper function to generate a human-readable timestamp
const getTimestamp = () => {
  const now = new Date();
  return now.toISOString(); // Example: "2023-10-25T14:48:00.000Z"
};

// Set the log level from environment variable (default to 'trace' if not set)
const logLevel = () => { return process.env.REACT_APP_CLIENT_LOG_LEVEL || 'trace' };
log.setLevel(logLevel());

// Customize the log format to include a timestamp and support multiple arguments
const originalFactory = log.methodFactory;
log.methodFactory = function (methodName, logLevel, loggerName) {
	const rawMethod = originalFactory(methodName, logLevel, loggerName);
	return function (...messages) {
		const timestamp = getTimestamp();
		rawMethod(`[${timestamp}] [${methodName.toUpperCase()}]`, ...messages);
	};
};
log.setLevel(log.getLevel()); // Apply the custom format

// Wrap log methods in a logger object for consistency
const logger = {
	trace: (...messages) => log.trace(...messages),
	debug: (...messages) => log.debug(...messages),
	info: (...messages) => log.info(...messages),
	warn: (...messages) => log.warn(...messages),
	error: (...messages) => log.error(...messages),
};

// Optionally, expose a method to change the log level dynamically
export const setLogLevel = (level) => {
	log.setLevel(level);
};

export default logger;