import React, { useState } from "react";
import { Button, Modal, Badge } from "react-bootstrap";
import { useTheme } from "./ThemeContext";
import { useDogContext } from './DogContext';
import DogModel from "../ObjectModels/DogModel";
import Hundeliste from './Hundeliste'; // Import Hundeliste component
import './HundParentPanel.css'

const HundParentPanel = ({ displayedParent }) => {
	const { isDarkMode } = useTheme();
	const { dogContextObject, updateDog } = useDogContext();
	const [ Parent, setParent] = useState(displayedParent);
	const [ ParentObject, setParentObject] = useState((Parent === "father" ? dogContextObject.vater || new DogModel() : dogContextObject.mutter || new DogModel()));

	const [showModal, setShowModal] = useState(false);

	const handleSelectDog = (selectedDog) => {
		const updatedDog = { ...dogContextObject };

		if (displayedParent === "father") {
			updatedDog.vaterId = selectedDog.id;
			updatedDog.vater = selectedDog;
		} 
		else {
			updatedDog.mutterId = selectedDog.id;
			updatedDog.mutter = selectedDog;
		}
		setParentObject(selectedDog);
		updateDog(updatedDog);
		setShowModal(false);
	};

	const handleCloseModal = () => setShowModal(false);
	const handleOpenModal = () => setShowModal(true);

	return (
		<div>
			<Badge>{ParentObject.hundname}</Badge>
			<Button onClick={handleOpenModal}>Load Parent</Button>

			<Modal show={showModal} onHide={handleCloseModal} size="xl">
				<Modal.Header closeButton>
					<Modal.Title>Select {Parent}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Hundeliste
						activeDisplay={Parent === "father" ? "zuchthunde (männl.)" : "zuchthunde (weibl.)"}
						renderTabs={true}
						renderButtons={false}
						onSelectDog={handleSelectDog}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default HundParentPanel;
