import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './Components/ThemeContext.js';
import QuickBoardMenu from './Components/QuickBoardMenu.js';
import Hunde from './Components/Hunde.js';
import Stammbuch from './Components/Stammbuch.js';
import AktuelleWuerfe from './Components/AktuelleWuerfe.js';
import Boxverteilung from './Components/Boxverteilung.js';
import NavigationPage from './Components/NavigationPage.js';
import KaufvertragDogContextProvider from './Components/KaufvertragDogContextProvider.js';
import FhemComponent from './Components/fhemComponent.js';
import './App.css';

function App() {
	const [isDarkMode, setIsDarkMode] = useState(true);

	useEffect(() => {
		console.log('App component is mounted.');
		document.title = "Heartbreaker Hunde-Manager";
	}, []);

	const toggleTheme = () => {
		console.log('Toggling theme. Current mode:', isDarkMode);
		setIsDarkMode(prevMode => !prevMode);
	};

	return (
		<ThemeProvider value={{ isDarkMode, toggleTheme }}>
			<Router>
				<div className="App">
					<div>
						<header className="App-header">Bettinas Heartbreaker Aussies</header>
						<QuickBoardMenu />
					</div>

					<main className="main-content">
						<Routes>
							<Route path="/sell" element={<KaufvertragDogContextProvider />} />
							<Route path="/dogs" element={<Hunde />} />
							<Route path="/stammbuch" element={<Stammbuch />} />
							<Route path="/aktuellewuerfe" element={<AktuelleWuerfe />} />
							<Route path="/boxverteilung" element={<Boxverteilung />} />
							<Route path="/fhem" element={<FhemComponent />} />
							<Route path="/" element={<NavigationPage />} />
						</Routes>
					</main>
				</div>
			</Router>
		</ThemeProvider>
	);
}

export default App;