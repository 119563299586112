import React, { useEffect } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import './Stammbuch.css';
import { useTheme } from './ThemeContext';
import {v4 as uuidv4 } from 'uuid'

const Stammbuch = () => {
	const [excelData, setExcelData] = React.useState([]);
	const { isDarkMode } = useTheme();

	useEffect(() => {
		fetchExcelData();
	},[]);

	const fetchExcelData = async () => {
		try {
			console.log('Fetching Excel data...');
			const response = await axios.get(`/api/documents/stammbuch/${uuidv4()}`);
			console.log('Excel data fetched successfully:', response.data);
			const transformedData = transformData(response.data);
			console.log('Transformed Excel data:', transformedData);
			setExcelData(transformedData);
			console.log('Excel data set to state.');
		} catch (error) {
			console.error('Error loading Excel data:', error);
		}
	};

	const transformData = (data) => {
		return data.map((obj) => {
		const transformedObj = {};
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				transformedObj[key] = transformValue(obj[key]);
			}
		}
		return transformedObj;
		});
	};

	const transformValue = (value) => {
		if (typeof value === 'object' && 'result' in value) {
			value = value.result;
		}
		if (typeof value === 'string' && !isNaN(Date.parse(value))) {
			return new Date(value).toISOString().split('T')[0];
		}
		if (typeof value === 'number') {
			return Number(value.toFixed(2));
		}
		return value;
	};

	return (
		<div className="stammbuch-container">
			<h2>Hundestammbuch</h2>
			<div className="table-container">
				<Table striped bordered hover className={`custom-table ${isDarkMode ? '-dark' : ''}`}>
				<thead>
					<tr>
						{excelData.length > 0 && Object.keys(excelData[0]).map((col, index) => (<th key={index}>{col}</th>))}
					</tr>
				</thead>
				<tbody>
					{excelData.map((row, rowIndex) => (
						<tr key={rowIndex}> 
							{Object.values(row).map((cell, cellIndex) => (<td key={`${rowIndex}-${cellIndex}`}>{cell}</td>))}
						</tr>
					))}
				</tbody>
				</Table>
			</div>
		</div>
	);
};

export default Stammbuch;