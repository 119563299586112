import React from "react";
// import PropTypes from 'prop-types';
import { DogProvider } from './DogContext.js';
import Kaufvertrag from "./Kaufvertrag.js";
import "./KaufvertragDogContextProvider.css";

const KaufvertragDogContextProvider = () => {
    return (
        <DogProvider>
            <Kaufvertrag />
        </DogProvider>
    );
};

export default KaufvertragDogContextProvider;
