import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NavigationPage.css';

const NavigationPage = () => {
  const navigate = useNavigate();

  const buttons = [
    { label: 'Hund verkaufen', route: '/sell' },
    { label: 'Alle Hunde anschauen', route: '/dogs' },
    { label: 'Stammbuch (Excel)', route: '/stammbuch' },
    { label: 'Wurf anlegen', route: '/litters' },
    { label: 'Lärmprotokoll', route: '/fhem' },
  ];

  return (
    <div className="NavigationPage-container">
      <h1 className="NavigationPage-title">Was willst du tun?</h1>
      <div className="NavigationPage-buttons">
        {buttons.map((button, index) => (
          <button key={index} className="NavigationPage-button" onClick={() => navigate(button.route)}>
            {button.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default NavigationPage;