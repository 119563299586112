import React, { Component } from 'react';
import QRCodeScanner from './QRCodeScanner';
import { Button } from 'react-bootstrap';
import './QRCodeScannerPanel.css'; // Import the CSS file

class QRCodeScannerPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panelMounted: false // Add state variable to track panel mounting
        };
    }

    componentDidMount() {
        // Update state to indicate panel is mounted
        this.setState({ panelMounted: true });
        console.log("QRCodeScannerPanel: Component mounted");
    }

    handleClosePanel = () => {
        console.log("QRCodeScannerPanel: Closing panel");
        // Close the panel by calling the onClose callback passed from the parent component
        // If no callback is provided, use a default behavior (e.g., do nothing)
        if (this.props.onClose) {
            console.log("QRCodeScannerPanel: Panel closed by parent component");
            this.props.onClose();
        } else {
            console.log("QRCodeScannerPanel: No onClose callback provided. Panel remains open.");
        }
    }

    handleScanSuccess = (decodedResult) => {
        if (!!decodedResult) {
            console.log("QRCodeScannerPanel: Scan success - Decoded Text:", decodedResult.text);
            
            const jsonString = decodedResult.text.replace(/'/g, '"');
            const scannedData = JSON.parse(jsonString);
            this.props.onScanSuccess(scannedData);
        }
    }

    render() {
        // Conditionally render QRCodeScanner based on panel mounting state
        return (
            <div className="qrScannerPanel">
                <div className="overlay" onClick={this.handleClosePanel}></div>
                <Button variant="danger" className="closeButton" onClick={this.handleClosePanel}>X</Button>
                <div className="panel">
                    {this.state.panelMounted && <QRCodeScanner onScan={this.handleScanSuccess} />}
                </div>
            </div>
        );
    }
}

export default QRCodeScannerPanel;
