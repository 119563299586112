import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { useDogContext } from '../Components/DogContext.js';
import { InputGroup, FormControl, Button, Spinner } from 'react-bootstrap';
import QRCodeScannerPanel from './QRCodeScannerPanel.js';
import HundProfilePanel from './HundProfilePanel.js';
import DogModel from '../ObjectModels/DogModel.mjs';
import { cancelRequest } from '../Utils/clientCancelUtils.mjs';
import './DogChipScanner.css';
import './../fontawesome/css/all.min.css';

const DogChipScanner = ({ chipnummer }) => {
    const { dogContextObject, updateDog } = useDogContext();
    const [chipNumber, setChipNumber] = useState(chipnummer || dogContextObject.chipnummer || '');
    const [loading, setLoading] = useState(false);
    const [showQRScannerPanel, setShowQRScannerPanel] = useState(false);
    const [panelOpen, setPanelOpen] = useState((dogContextObject.chipnummer > ''));
    const [scanError, setError] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const scanRequest = useRef(null);

    // Cancel the search request when the component unmounts
    useEffect(() => {
        return () => {
            if (scanRequest.current) {
                cancelRequest(scanRequest.current);
            }
        };
    }, []);

    const handleInputChange = (event) => {
        setChipNumber(event.target.value);
        setSearchStatus('');
    };

    const handleSearch = async () => {
        if (loading) {
            await cancelRequest(scanRequest.current);
            setLoading(false);
            scanRequest.current = null;
            return;
        }

        if (!chipNumber.trim()) {
            setError('Bitte geben Sie eine Chipnummer ein.');
            setSearchStatus('error');
            return;
        }
        setLoading(true);
        setError('');
        setSearchStatus('');
        updateDog(null);
        setPanelOpen(false);

        scanRequest.current = `chipnummer-${chipNumber}`;
        try {
			const response = await axios.get(`/api/dogs/chipnummer/${chipNumber}/${scanRequest.current}`);
			if (response.data) {
				updateDog(response.data); // Store in context
				setSearchStatus('success');
				setPanelOpen(true);
			} else {
				setError('Hund nicht gefunden.');
				updateDog(null);
				setSearchStatus('error');
				setPanelOpen(true);
			}
            setTimeout(() => setSearchStatus(''), 1000);
        } catch (error) {
            setError('Fehler bei der Suche: ' + error.message);
            setSearchStatus('error');
            setPanelOpen(true);
            setTimeout(() => setSearchStatus(''), 1000);
        } finally {
            setLoading(false);
            scanRequest.current = null;
        }
    };

    const handleQRCodeScanClose = (chipNumber) => {
        setChipNumber(chipNumber);
        setShowQRScannerPanel(false);
        handleSearch();
    };

    const addNewDog = () => {
        let newDog = new DogModel();
        newDog = { ...newDog, chipnummer: chipNumber };
        updateDog(newDog); // Store new dog in context
		setError('');
        setPanelOpen(true);
    };

    const cancelAddNewDog = () => {
        setPanelOpen(false);
        setError('');
        updateDog(null);
    };

    return (
        <div className="DogChipScanner-container">
            <InputGroup className="DogChipScanner-inputGroup">
                <FormControl
                    placeholder="Enter Chip Number"
                    value={chipNumber}
                    onChange={handleInputChange}
                    className={`DogChipScanner-input ${searchStatus === 'success' ? 'border-success' : searchStatus === 'error' ? 'border-danger' : ''}`}
                />
                <Button
                    variant={loading ? 'danger' : 'outline-secondary'}
                    onClick={handleSearch}
                    className="DogChipScanner-button"
                    disabled={!chipNumber.trim()}
                >
                    {loading ? <Spinner as="span" animation="border" size="sm" /> : <i className="fas fa-search"></i>}
                </Button>
                <Button
                    variant="outline-secondary"
                    onClick={() => setShowQRScannerPanel(true)}
                    className="DogChipScanner-button"
                >
                    <i className="fas fa-qrcode"></i>
                </Button>
            </InputGroup>

            {panelOpen && (
                <div className="DogChipScanner-panel open">
                    {scanError == '' ? (
                        <HundProfilePanel tabsheet="profil" />
                    ) : (
                        <div className='DogChipScanner-panel-buttons'>
                            <p>Hund nicht gefunden. Möchten Sie einen neuen Hund mit dieser Chipnummer anlegen?</p>
							<div>
								<Button className='DogChipScanner-panel-button' onClick={addNewDog}>Ja</Button>
								<Button className='DogChipScanner-panel-button' onClick={cancelAddNewDog}>Nein</Button>
							</div>
                        </div>
                    )}
                </div>
            )}

            {showQRScannerPanel && (
                <QRCodeScannerPanel
                    onScanSuccess={handleQRCodeScanClose}
                    onClose={() => setShowQRScannerPanel(false)}
                />
            )}
        </div>
    );
};

export default DogChipScanner;
