import React, { useState, useEffect } from "react";
import { Tab, Tabs, FormControl } from "react-bootstrap";
import axios from "axios";
import { newDogObject } from './DogContext';
import { mapToDogModel } from '../ObjectModels/DogModel'
import InfiniteScroll from 'react-infinite-scroll-component';
import HundProfilePanelProvider from "./HundProfilePanelProvider";
import "./Hundeliste.css";

const nineMonths = 0.75;

const debugDog = [
	{
	  id: 784,
	  hundname: "Nelli",
	  kennelname: "Heartbreaker",
	  geburtsdatum: "2008-07-07T15:45:58.000Z",
	  chipnummer: "276097200744426",
	  farbe: "BlueMerle",
	  statur: "Mini",
	  augenfarbelinks: "Blau",
	  augenfarberechts: "Blau",
	  mutterid: -100,
	  vaterid: -100,
	  besitzerid: 785,
	  visible: 1,
	  istruede: 0,
	  adatum: "2022-11-01T11:25:56.000Z",
	  edatum: "2020-09-21T15:45:59.000Z",
	  wurfnummer: "2014-Wurf-00001",
	  stammhund: 0,
	  registriertername: "",
	  zuchtbuchnummer: "",
	  status: "Verkauft",
	  rasse: null,
	  registrierteinstanz: null,
	  trimfarbe: null,
	  rute: null,
	  impfungen: "",
	  lastta: "1899-12-29T23:00:00.000Z",
	  nextta: "1899-12-29T23:00:00.000Z",
	  get alter() {
		const birthDate = new Date(this.geburtsdatum);
		const currentDate = new Date();
		const ageInMilliseconds = currentDate - birthDate;
		const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
		return ageInYears.toFixed(2);
	}
	},
	{
	  id: 8,
	  hundname: "Blue Merle Mini Hündin",
	  kennelname: "",
	  geburtsdatum: "2019-01-09T18:34:41.000Z",
	  chipnummer: "900113001292995",
	  farbe: "BlueMerle",
	  statur: "Mini",
	  augenfarbelinks: "Blau",
	  augenfarberechts: "Blau",
	  mutterid: -100,
	  vaterid: -100,
	  besitzerid: 610,
	  visible: 1,
	  istruede: 0,
	  adatum: "2022-11-01T12:29:55.000Z",
	  edatum: "2020-05-26T17:34:41.000Z",
	  wurfnummer: "",
	  stammhund: 0,
	  registriertername: "",
	  zuchtbuchnummer: "",
	  status: "Verkauft",
	  rasse: null,
	  registrierteinstanz: null,
	  trimfarbe: null,
	  rute: null,
	  impfungen: "",
	  lastta: "1899-12-29T23:00:00.000Z",
	  nextta: "1899-12-29T23:00:00.000Z",
	  get alter() {
		const birthDate = new Date(this.geburtsdatum);
		const currentDate = new Date();
		const ageInMilliseconds = currentDate - birthDate;
		const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
		return ageInYears.toFixed(2);
	}
	},
	{
	  id: 14,
	  hundname: "Cookie Karma",
	  kennelname: "Heartbreaker",
	  geburtsdatum: "2019-01-09T18:34:41.000Z",
	  chipnummer: "900113001292993",
	  farbe: "BlackTri",
	  statur: "Mini",
	  augenfarbelinks: "Blau",
	  augenfarberechts: "Blau",
	  mutterid: -100,
	  vaterid: -100,
	  besitzerid: 613,
	  visible: 1,
	  istruede: 0,
	  adatum: "2022-11-01T12:30:02.000Z",
	  edatum: "2020-05-26T17:34:41.000Z",
	  wurfnummer: "",
	  stammhund: 0,
	  registriertername: "",
	  zuchtbuchnummer: "",
	  status: "Verkauft",
	  rasse: null,
	  registrierteinstanz: null,
	  trimfarbe: null,
	  rute: null,
	  impfungen: "SHPL",
	  lastta: "2019-03-19T23:00:00.000Z",
	  nextta: "2019-04-19T22:00:00.000Z",
	  get alter() {
		const birthDate = new Date(this.geburtsdatum);
		const currentDate = new Date();
		const ageInMilliseconds = currentDate - birthDate;
		const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
		return ageInYears.toFixed(2);
	}
	},
	{
	  id: 20,
	  hundname: "Black Tri Mini Rüde",
	  kennelname: "",
	  geburtsdatum: "2019-01-09T18:34:42.000Z",
	  chipnummer: "900113001293172",
	  farbe: "BlackTri",
	  statur: "Mini",
	  augenfarbelinks: "Blau",
	  augenfarberechts: "Blau",
	  mutterid: -100,
	  vaterid: -100,
	  besitzerid: 614,
	  visible: 1,
	  istruede: 1,
	  adatum: "2022-11-01T12:56:26.000Z",
	  edatum: "2020-05-26T17:34:42.000Z",
	  wurfnummer: "",
	  stammhund: 0,
	  registriertername: "",
	  zuchtbuchnummer: "",
	  status: "Verkauft",
	  rasse: null,
	  registrierteinstanz: null,
	  trimfarbe: null,
	  rute: null,
	  impfungen: "",
	  lastta: "1899-12-29T23:00:00.000Z",
	  nextta: "1899-12-29T23:00:00.000Z",
	  get alter() {
		const birthDate = new Date(this.geburtsdatum);
		const currentDate = new Date();
		const ageInMilliseconds = currentDate - birthDate;
		const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
		return ageInYears.toFixed(2);
	}
	},
	{
	  id: 27,
	  hundname: "Black Tri Mini Huendin",
	  kennelname: "",
	  geburtsdatum: "2019-01-09T18:34:43.000Z",
	  chipnummer: "900113001293162",
	  farbe: "BlackTri",
	  statur: "Mini",
	  augenfarbelinks: "Braun",
	  augenfarberechts: "Blau",
	  mutterid: -100,
	  vaterid: -100,
	  besitzerid: 615,
	  visible: 1,
	  istruede: 0,
	  adatum: "2022-11-01T13:10:02.000Z",
	  edatum: "2020-05-26T17:34:43.000Z",
	  wurfnummer: "",
	  stammhund: 0,
	  registriertername: "",
	  zuchtbuchnummer: "",
	  status: "Verkauft",
	  rasse: null,
	  registrierteinstanz: null,
	  trimfarbe: null,
	  rute: null,
	  impfungen: "",
	  lastta: "1899-12-29T23:00:00.000Z",
	  nextta: "1899-12-29T23:00:00.000Z",
	  get alter() {
		const birthDate = new Date(this.geburtsdatum);
		const currentDate = new Date();
		const ageInMilliseconds = currentDate - birthDate;
		const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
		return ageInYears.toFixed(2);
	}
	},
  ];

const Hundeliste = ({ activeDisplay, filter, renderTabs, renderButtons, handleSelectDog }) => {
	//const { dogContextObject, updateDog } = useDogContext();
	const [allDogs, setAllDogs] = useState([]);
	const [activeTab, setActiveTab] = useState(activeDisplay || "alle");
	const [tabFilter, setTabFilter] = useState({fieldFilters: []});
	const [tabDogs, setTabDogs] = useState([]);
	const [filteredDogs, setFilteredDogs] = useState([]);
	const [selectedDogs, setSelectedDogs] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [customFilter, setCustomFilter] = useState(filter);
	const [hasMore, setHasMore] = useState(true);
	const [offset, setOffset] = useState(0);
	const limit = 5;
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		let newTabFilter = {};

		switch (activeTab) {
			case "alle":
				newTabFilter = { 
					fieldFilters: [] 
				}; 
			break;
			case "zuchthunde":
				newTabFilter = {
					fieldFilters: [
						{ field: "alter", operator: ">=", fieldvalue: nineMonths }
					]
				};
			break;
			case "zuchthunde (männl.)":
				newTabFilter = {
					fieldFilters: [
						{ field: "istruede", operator: "=", fieldvalue: 1 },
						{ field: "alter", operator: ">=", fieldvalue: nineMonths }
					]
				};
			break;
			case "zuchthunde (weibl.)":
				newTabFilter = {
					fieldFilters: [
						{ field: "istruede", operator: "=", fieldvalue: 0 },
						{ field: "alter", operator: ">=", fieldvalue: nineMonths }
					]
				};
			break;
			case "bestandshunde":
				newTabFilter = {
					fieldFilters: [
						{ field: "stammhund", operator: "=", fieldvalue: 1 }
					]
				};
			break;
			case "welpen":
				newTabFilter = {
					fieldFilters: [
						{ field: "alter", operator: "<", fieldvalue: nineMonths }
					]
			};
			break;
			case "welpen (männl.)":
				newTabFilter = {
					fieldFilters: [
						{ field: "istruede", operator: "=", fieldvalue: 1 },
						{ field: "alter", operator: "<", fieldvalue: nineMonths }
					]
			};
			break;
			case "welpen (weibl.)":
				newTabFilter = {
					fieldFilters: [
						{ field: "istruede", operator: "=", fieldvalue: 0 },
						{ field: "alter", operator: "<", fieldvalue: nineMonths }
					]
				};
			break;
			default:
			break;
		}
		// console.info("setting tabfilter: ", newTabFilter);
		// console.info("Filterable objects: ", allDogs);
		setTabFilter(newTabFilter);
	}, [activeTab, allDogs]);

	useEffect(() => {
		// console.info("applying tabfilter: ", tabFilter);
		// console.info("Filterable objects: ", allDogs);
		applyTabFilter();
	}, [tabFilter]);

	const applyTabFilter = () => {
		
		if (!tabFilter){
			return;
		}

		// console.info("Inside applyTabfilter with: ", tabFilter);
		// console.info("Filterable objects: ", allDogs);
		
		if(tabFilter.fieldFilters.length === 0) {
			// console.info("tabFilter is empty using allDogs for tabDogs: ", allDogs);
			setTabDogs(allDogs);
			return;
		}

		const filteredToTabDogs = allDogs.filter(dog => {
			try {
				return tabFilter.fieldFilters.every(filter => {
					try {
						let fieldValue = filter.fieldvalue; // Convert field value to lowercase
						let dogValue = dog[filter.field]; // Convert dog value to lowercase

						console.info("filter: ", filter);
						
						if (typeof fieldValue === 'string') {
							fieldValue = fieldValue.toLowerCase();
						}
						
						if (typeof dogValue === 'string') {
							dogValue = dogValue.toLowerCase();
						}

						switch (filter.operator) {
							case "=":
								return dogValue === fieldValue;
							case ">=":
								return dogValue >= fieldValue;
							case "<>":
								return dogValue !== fieldValue;
							case "<=":
								return dogValue <= fieldValue;
							case "*":
								return dogValue.includes(fieldValue);
							default:
								return true;
						}
					} 
					catch (catchError) {
						console.info("error: ", catchError);
						return false;
					}
				});
	  		} 
	  		catch (catchErrorOuter) {
				console.error("Error applying tab filter:", catchErrorOuter);
				return false;
	  		}
		});
		// console.info("setting tabDogs from: ", tabDogs);
		// console.info("setting tabDogs to: ", filteredToTabDogs);
		setTabDogs(filteredToTabDogs);
  	};

	useEffect(() => {
		// console.info("tabDogs changed, applying : ", searchText);
		applySearchText();
	}, [searchText,tabDogs]);


	const applySearchText = () => {
		console.info("inside applySearchText: ", searchText);
		if(searchText === "") {
			console.info("searchtext empty using tabDogs for filteredDogs: ", tabDogs);
			setFilteredDogs(tabDogs);
			return;
		}

		if (!tabFilter){
			return;
		}

		const tabFilterProperties = tabFilter.fieldFilters.map(filter => filter.field);

		const textFilter = {
			fieldFilters: Object.keys(newDogObject())
			.filter(property =>
				!(
					["id", "profilbild", "galerie", "visible", "mutter", "mutterId", "vater", "vaterId", "besitzer", "besitzerId"]
					.includes(property) ||
					tabFilterProperties.includes(property)
				)
			)
			.map(property => ({
				field: property,
				operator: "*",
				fieldvalue: searchText.toLowerCase()
			}))
		};

		const filtered = tabDogs.filter(dog => {
			try {
				return textFilter.fieldFilters.some(filter => {
					try{
						const fieldValue = filter.fieldvalue;
						const dogValue = dog[filter.field].toLowerCase();
						switch (filter.operator) {
						case "=":
							return dogValue === fieldValue;
						case ">=":
							return dogValue >= fieldValue;
						case "<>":
							return dogValue !== fieldValue;
						case "<=":
							return dogValue <= fieldValue;
						case "*":
							return dogValue.includes(fieldValue);
						default:
							return true;
						}
					} 
					catch (error) {
					  return true;
					}
				});
			} 
			catch (error) {
				console.error("Error applying search text filter:", error);
				return false;
			}
		});

		setFilteredDogs(filtered);
	};

	const handleTabChange = key => {
		// console.info("switching tab to: ", key);
		setActiveTab(key);
	};

	const handleDogClick = dog => {
		// Add or remove dog from selectedDogs
		const updatedSelectedDogs = selectedDogs.includes(dog)
			? selectedDogs.filter(selectedDog => selectedDog !== dog)
			: [...selectedDogs, dog];
		setSelectedDogs(updatedSelectedDogs);
		handleSelectDog(dog);
	};

	const fetchData = async () => {
		try {
			const response = await axios.get("/api/dogs", {
				params: {
					offset,
					limit
				}
			});
            const newDogs = response.data.map(mapToDogModel);

			setAllDogs(prevDogs => [...prevDogs, ...newDogs]);
			setOffset(prevOffset => prevOffset + limit);

			if (newDogs.length < limit) {
				setHasMore(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setError(error);
		}
	};

	return (
		<div>
			<FormControl placeholder="Filter" onChange={e => setSearchText(e.target.value)} />
			{renderTabs && (
				<Tabs defaultActiveKey={activeDisplay} id="hundeliste-tabs" onSelect={handleTabChange}>
					<Tab eventKey="alle" title="Alle Hunde" />
					<Tab eventKey="zuchthunde" title="Alle Zuchthunde" />
					<Tab eventKey="zuchthunde (männl.)" title="Rüden" />
					<Tab eventKey="zuchthunde (weibl.)" title="Hündinnen" />
					<Tab eventKey="bestandshunde" title="Hunde im Bestand" />
					<Tab eventKey="welpen" title="Alle Welpen" />
					<Tab eventKey="welpen (männl.)" title="Welpen (männl.)" />
					<Tab eventKey="welpen (weibl.)" title="Welpen (weibl.)" />
				</Tabs>
			)}
			<div id="scrollableDiv" className="scrollableDiv">
				{filteredDogs && filteredDogs.length>0 && (
					<InfiniteScroll 
						dataLength={filteredDogs.length}
						next={fetchData}
						hasMore={hasMore}
						loader={<p>Loading...</p>}
						endMessage={<p style={{ textAlign: 'center' }}><b>You have seen it all</b></p>}
						scrollableTarget="scrollableDiv"
						style={{ display: 'flex', flexDirection: 'column' }} 
					>
					{
						filteredDogs.map(dog => (
							<HundProfilePanelProvider key={dog.id} dogData={dog} />
						))
					}
					</InfiniteScroll>
				)}
				{error && <p>Error: {error.message}</p>}
			</div>
		</div>
	);
};

export default Hundeliste;
