import React from 'react';
import { useTheme } from './ThemeContext';
import { Container } from 'react-bootstrap';
import './Boxverteilung.css';

const Boxverteilung = () => {
  const { isDarkMode } = useTheme();

  return (
    <Container className={`boxverteilung-container ${isDarkMode ? 'dark' : ''}`}>
      <h1>Boxverteilung</h1>
      {/* Add your content here */}
    </Container>
  );
};

export default Boxverteilung;
