import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import DropdownWithOptions from "./DropDownWithOptions.js";
import GenderSwitch from "./GenderSwitch.js";
import HundProfilImage from "./HundProfilImage.js";
import { useDogContext } from './DogContext.js';
import HundParentPanel from './HundParentPanel.js';
import { FaEye, FaUser, FaPaw, FaCalendarAlt, FaVenusMars, FaIdCard } from "react-icons/fa";
import './TabsheetHundeProfil.css';

import {
	AugenfarbeOptions,
	FellfarbeOptions,
	HunderasseOptions,
	HundstatusOptions,
	InstitutOptions,
	RutenlaengeOptions,
	StaturOptions,
	TrimFarbeOptions,
} from "./DropDownOptions";

const TabsheetHundeProfil = () => {
	const { dogContextObject, updateDog } = useDogContext();
	const [showImage, setShowImage] = useState(true);

	const handleChange = (e) => {
		const { name, value } = e.target;
		const propName = name;
		const propValue = value;
		const newDog = { ...dogContextObject, [propName]: propValue };
		updateDog(newDog);

		// Automatically set "Nächster Tierarzttermin" to 1 month after "Letzter Tierarztbesuch"
		if (name === "lastta") {
			const lasttaDate = new Date(value);
			const nexttaDate = new Date(lasttaDate.setMonth(lasttaDate.getMonth() + 1));
			updateDog({ ...newDog, nextta: nexttaDate.toISOString().split('T')[0] });
		}
	};

	useEffect(() => {
		setShowImage(false);
			const timeoutId = setTimeout(() => {
			setShowImage(true);
		}, 1000);
		return () => clearTimeout(timeoutId);
	}, [dogContextObject.profilbild]);

	return (
		<div className="tabsheet-hunde-profil-body-grid-container">
			<div className="tabsheet-hunde-profil-grid-cell-profilbild">
				{showImage ? (
					<HundProfilImage
						displayShape="rectangular"
						tabIndex={2}
						className="tabsheet-hunde-profil-profilbild"
						canMaximizeImage={true}
						renderUploadButtons={true}
						key={dogContextObject.id}
						canFetch={true}
					/>
				) : (
					<Spinner as="span" animation="border" size="sm" />
				)}
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-mutterId">
				<HundParentPanel tabIndex={9} displayedParent="mother" />
				<label className="input-label"><FaPaw /> Mutter:</label>
				<span>{dogContextObject.mutterId}</span>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-vaterId">
				<HundParentPanel tabIndex={10} displayedParent="father" />
				<label className="input-label"><FaPaw /> Vater:</label>
				<span>{dogContextObject.vaterId}</span>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-besitzerId">
				<label className="input-label"><FaUser /> Aktueller Besitzer:</label>
				<input
					tabIndex={11}
					type="text"
					name="besitzerId"
					value={dogContextObject.besitzerId}
					placeholder="Aktueller Besitzer"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-hundname">
				<label className="input-label"><FaPaw /> Name des Hundes:</label>
				<input
					tabIndex={5}
					type="text"
					name="hundname"
					value={dogContextObject.hundname}
					placeholder="Name des Hundes"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-kennelname">
				<label className="input-label"><FaIdCard /> Name des Kennels:</label>
				<input
					tabIndex={20}
					type="text"
					name="kennelname"
					value={dogContextObject.kennelname}
					placeholder="Name des Kennels"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-geburtsdatum">
				<label className="input-label"><FaCalendarAlt /> Geboren am:</label>
				<input
					tabIndex={6}
					type="date"
					name="geburtsdatum"
					value={dogContextObject.geburtsdatum}
					placeholder="Geboren am"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-chipnummer">
				<label className="input-label"><FaIdCard /> Chipnummer:</label>
				<input
					tabIndex={4}
					type="text"
					name="chipnummer"
					value={dogContextObject.chipnummer}
					placeholder="Chipnummer"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-farbe">
				<label className="input-label">Fellfarbe:</label>
				<DropdownWithOptions
					tabIndex={14}
					name="farbe"
					options={FellfarbeOptions}
					onChange={handleChange}
					value={dogContextObject.farbe}
					key={dogContextObject.farbe}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-statur">
				<label className="input-label">Größe:</label>
				<DropdownWithOptions
					tabIndex={16}
					name="statur"
					options={StaturOptions}
					onChange={handleChange}
					value={dogContextObject.statur}
					key={dogContextObject.statur}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-augenfarbe">
				<label className="input-label"><FaEye /> Augenfarbe:</label>
				<div>
					<div className="dropdown-container">
						<DropdownWithOptions
							tabIndex={7}
							name="augenfarbelinks"
							options={AugenfarbeOptions}
							onChange={handleChange}
							value={dogContextObject.augenfarbelinks}
							key={dogContextObject.augenfarbelinks}
						/>
					</div>
					<div className="dropdown-container">
						<DropdownWithOptions
							tabIndex={8}
							name="augenfarberechts"
							options={AugenfarbeOptions}
							onChange={handleChange}
							value={dogContextObject.augenfarberechts}
							key={dogContextObject.augenfarberechts}
						/>
					</div>
				</div>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-istruede">
				<GenderSwitch
					tabIndex={1}
					name="istruede"
					label={<><FaVenusMars /> Geschlecht:</>}
					onChange={handleChange}
					istruede={dogContextObject.istruede}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-wurfnummer">
				<label className="input-label">Wurfnummer:</label>
				<input
					tabIndex={3}
					type="text"
					name="wurfnummer"
					value={dogContextObject.wurfnummer}
					placeholder="Wurfnummer"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-stammhund tabsheet-hunde-profil-form-switch">
				<label className="input-label">Ist Stammhund?:</label>
				<input
					tabIndex={12}
					className="tabsheet-hunde-profil-form-check-input"
					type="checkbox"
					name="stammhund"
					checked={dogContextObject.stammhund}
					onChange={(e) => handleChange({ target: { name: "stammhund", value: e.target.checked ? true : false } })} //{handleChange}
				/>
				<span>{dogContextObject.stammhund}</span>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-registrierterName">
				<label className="input-label">Registrationsname:</label>
				<input
					tabIndex={23}
					type="text"
					name="registrierterName"
					value={dogContextObject.registrierterName}
					placeholder="Registrationsname"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-zuchtbuchnummer">
				<label className="input-label">Zuchtbuchnummer:</label>
				<input
					tabIndex={19}
					type="text"
					name="zuchtbuchnummer"
					value={dogContextObject.zuchtbuchnummer}
					placeholder="Zuchtbuchnummer"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-status">
				<label className="input-label">Status:</label>
				<DropdownWithOptions
					tabIndex={18}
					name="status"
					options={HundstatusOptions}
					onChange={handleChange}
					value={dogContextObject.status}
					key={dogContextObject.status}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-rasse">
				<label className="input-label">Typ:</label>
				<DropdownWithOptions
					tabIndex={13}
					name="rasse"
					options={HunderasseOptions}
					onChange={handleChange}
					value={dogContextObject.rasse}
					key={dogContextObject.rasse}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-registrierteInstanz">
				<label className="input-label">Registriert bei:</label>
				<DropdownWithOptions
					tabIndex={23}
					name="registrierteInstanz"
					options={InstitutOptions}
					onChange={handleChange}
					value={dogContextObject.registrierteInstanz}
					key={dogContextObject.registrierteInstanz}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-trimfarbe">
				<label className="input-label">Zierfarbe:</label>
				<DropdownWithOptions
					tabIndex={15}
					name="trimfarbe"
					options={TrimFarbeOptions}
					onChange={handleChange}
					value={dogContextObject.trimfarbe}
					key={dogContextObject.trimfarbe}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-rute">
				<label className="input-label">Rute:</label>
				<DropdownWithOptions
					tabIndex={17}
					name="rute"
					options={RutenlaengeOptions}
					onChange={handleChange}
					value={dogContextObject.rute}
					key={dogContextObject.rute}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-impfungen">
				<label className="input-label">Impfungen:</label>
				<textarea
					tabIndex={24}
					name="impfungen"
					value={dogContextObject.impfungen}
					placeholder="Impfungen"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-lastta">
				<label className="input-label">Letzter Tierarztbesuch:</label>
				<input
					tabIndex={21}
					type="date"
					name="lastta"
					value={dogContextObject.lastta}
					placeholder="Letzter Tierarztbesuch"
					onChange={handleChange}
				/>
			</div>
			<div className="tabsheet-hunde-profil-grid-cell-nextta">
				<label className="input-label">Nächster Tierarzttermin:</label>
				<input
					tabIndex={22}
					type="date"
					name="nextta"
					value={dogContextObject.nextta}
					placeholder="Nächster Tierarzttermin"
					onChange={handleChange}
				/>
			</div>
		</div>
	);
};

export default TabsheetHundeProfil;