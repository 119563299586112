export const AugenfarbeOptions = [
    { label: "Blau", value: "Blau" },
    { label: "Braun", value: "Braun" },
    { label: "Amber", value: "Amber" },
    { label: "Marbled", value: "Marbled" },
  ];
  
  export const FellfarbeOptions = [
    { label: "BlackTri", value: "BlackTri" },
    { label: "RedTri", value: "RedTri" },
    { label: "BlueMerle", value: "BlueMerle" },
    { label: "RedMerle", value: "RedMerle" },
    { label: "BlackBi", value: "BlackBi" },
    { label: "RedBi", value: "RedBi" },
    { label: "BlackSolid", value: "BlackSolid" },
    { label: "RedSolid", value: "RedSolid" },
  ];
  
  export const TrimFarbeOptions = [
    { label: "Kupfer", value: "Kupfer" },
    { label: "Weiss", value: "Weiss" },
    { label: "WC", value: "WC" },
  ];
  
  export const InstitutOptions = [
    { label: "Keine", value: "Keine" },
    { label: "ASCA", value: "ASCA" },
    { label: "MASCA", value: "MASCA" },
    { label: "ASDR", value: "ASDR" },
  ];
  
  export const RutenlaengeOptions = [
    { label: "Lang", value: "Lang" },
    { label: "Kupiert", value: "Kupiert" },
    { label: "NBT", value: "NBT" },
  ];
  
  export const HunderasseOptions = [
    { label: "Aussie", value: "Aussie" },
    { label: "Doodle", value: "Doodle" },
  ];
  
  export const HundstatusOptions = [
    { label: "Vorhanden", value: "Vorhanden" },
    { label: "Reserviert", value: "Reserviert" },
    { label: "Verkauft", value: "Verkauft" },
    { label: "Gnadenbrot", value: "Gnadenbrot" },
    { label: "Verstorben", value: "Verstorben" },
  ];
  
  export const GenderOptions = [
    { label: "Ruede", value: "Ruede" },
    { label: "Huendin", value: "Huendin" },
  ];
  
  export const StandOptions = [
    { label: "Zuchthund", value: "Zuchthund" },
    { label: "Deckhund", value: "Deckhund" },
    { label: "Welpe", value: "Welpe" },
    { label: "Leihhund", value: "Leihhund" },
    { label: "Unbekannt", value: "Unbekannt" },
  ];
  
  export const StaturOptions = [
    { label: "Standard", value: "Standard" },
    { label: "Mini", value: "Mini" },
    { label: "Toy", value: "Toy" },
  ];
  
  export const BesitzerStatusOptions = [
    { label: "Owner", value: "Owner" },
    { label: "CoOwner", value: "CoOwner" },
  ];
  
  export const WurfStatusOptions = [
    { label: "Geplant", value: "Geplant" },
    { label: "Offen", value: "Offen" },
    { label: "Bereit", value: "Bereit" },
    { label: "Abgeschlossen", value: "Abgeschlossen" },
  ];
  
  export const AnredeOptions = [
    { label: "Herr", value: "Herr" },
    { label: "Frau", value: "Frau" },
    { label: "Sonder", value: "Sonder" },
  ];
  
  export const KundenstatusOptions = [
    { label: "Neu", value: "Neu" },
    { label: "Züchter", value: "Züchter" },
    { label: "Interessent", value: "Interessent" },
    { label: "Käufer", value: "Käufer" },
  ];
  