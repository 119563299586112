import { formatDate } from '../Utils/dateUtils.mjs';
import Mime from 'mime';

const currentDate = new Date();
const DateiModel = {
	id: -1,
	dateiname: "",
	adatum: formatDate(currentDate),
	edatum: formatDate(currentDate),
	daten: null,
	extension: "",
	hundid: -1,
	kundenid: -1,
	visible: 1
};

// Function to map database result to DateiModel
export const mapToDateiModel = (datei) => {
	return Object.keys(DateiModel).reduce((mappedDatei, key) => {
		if (key === 'daten') {
			const mimeType = Mime.getType(datei.EXTENSION.substring(1)) || 'application/octet-stream';
            mappedDatei[key] = `data:${mimeType};base64,${datei.DATEN.toString('base64')}`;
		} else {
			mappedDatei[key] = datei[key.toUpperCase()]; // Assuming the keys in the result are in uppercase
		}
		return mappedDatei;
	}, {});
};

export default DateiModel;