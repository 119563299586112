import React, { Component } from 'react';
import QrReader from 'react-qr-scanner';
import { isMobile } from 'react-device-detect';

const qrcodeRegionId = "qr-code-full-region";

class QRCodeScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delay: 3000,
            result: 'No result',
            errorMessage: null
        };
    }

    handleScan = (data) => {
        const resultText = data ? data.text : 'No result';
        this.setState( {result: resultText},() => {this.props.onScan(data);} );
    }

    handleError = (err) => {
        console.error(err);
        // Set error message
        this.setState({ errorMessage: err.message });
    }

    render() {
        const previewStyle = {
            height: 480, // Double the height
            width: 640, // Double the width
        };

        const constraints = {
            aspectRatio: 1,
            video: { facingMode: isMobile ? "environment" : "user" },
        };

        return (
            <div id={qrcodeRegionId}>
                <QrReader
                    delay={this.state.delay}
                    style={previewStyle}
                    key="environment"
                    videoId={qrcodeRegionId}
                    constraints={constraints}
                    onError={this.handleError}
                    onScan={this.handleScan}
                />
                <p style={{ color: 'red' }}>{this.state.errorMessage}</p>
                <p>{this.state.result}</p>
            </div>
        );
    }
}

export default QRCodeScanner;