import axios from 'axios';
import logger from '../Utils/clientLogger.mjs';

export const makeProfilbildApiCall = async (dogId, requestId) => {
	try {
		const response = await axios.get(`/api/dogs/${dogId}/getProfilbild/${requestId}`, {
			timeout: 60000,
			timeoutErrorMessage: `Timeout beim laden des Profilbilds ${requestId}`
		});
		return `data:image/jpeg;base64,${response.data}`;
	} catch (error) {
		logger.warn(`Error fetching Profilbild ${requestId}, delegate errorhandleing to DogContext:`, { message: error.message} );
		throw error;
	}
};

export const makeGalerieApiCall = async (dogId, ids, offset, limit, requestId) => {
	try {
		const response = await axios.post(`/api/dogs/${dogId}/getSomeFiles`, {
			ids,
			offset,
			limit,
			requestId: requestId
		}, {
			timeout: (limit * 60000),
			timeoutErrorMessage: `Timeout while fetching some files ${requestId}`
		});

		return response.data;
	} catch (error) {
		logger.warn(`Error fetching Galerie: ${requestId}, delegate errorhandleing to DogContext:`, { message: error.message, hundeid: dogId, idArray: ids, offset: offset, limit: limit, errorstack: error} );
		throw error;
	}
};

// New CRUD functions
export const updateDogInDatabase = async (dogData) => {
	try {
		const response = await axios.put(`/api/dogs/update/update-${dogData.id}`, dogData);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 499) {
			logger.debug(`Update request for dog ${dogData.id} was canceled by the user.`);
		} else {
			logger.error(`Error updating dog ${dogData.id}:`, { message: error.message });
		}
		throw error;
	}
};

export const deleteDogInDatabase = async (dogId) => {
	try {
		const response = await axios.delete(`/api/dogs/delete/${dogId}/delete-${dogId}`);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 499) {
			logger.debug(`Delete request for dog ${dogId} was canceled by the user.`);
		} else {
			logger.error(`Error deleting dog ${dogId}:`, { message: error.message });
		}
		throw error;
	}
};

export const reloadDogFromDatabase = async (dogId) => {
	try {
		const response = await axios.get(`/api/dogs/${dogId}/refresh-${dogId}`, {
			timeout: 300000,
			timeoutErrorMessage: "Timeout while reloading dog data"
		});
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 404) {
			logger.debug(`Refresh request for dog ${dogId} failed as dog doesn't exist.`);
		} else if (error.response && error.response.status === 499) {
			logger.debug(`Refresh request for dog ${dogId} was canceled by the user.`);
		} else {
			logger.error(`Error refreshing dog ${dogId}:`, { message: error.message });
		}
		throw error;
	}
};