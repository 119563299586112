import React, { useState } from "react";
import { DogProvider } from './DogContext';
import HundProfilePanel from "./HundProfilePanel";
import PropTypes from 'prop-types';
import "./HundProfilePanelProvider.css";

const HundProfilePanelProvider = ({ dogData }) => {
	const [isDogDeleted, setIsDogDeleted] = useState(false);

	// Function to handle dog deletion
	const handleDogDeleted = () => {
		setIsDogDeleted(true); // Set state to unmount HundProfilePanel
	};

	return (
		<DogProvider dogData={dogData}>
			{!isDogDeleted && (
				<HundProfilePanel tabsheet="profil" onDogDeleted={handleDogDeleted}/>
			)}
		</DogProvider>
	);
};

HundProfilePanelProvider.propTypes = {
	dogData: PropTypes.instanceOf(Object).isRequired,
};

export default HundProfilePanelProvider;
