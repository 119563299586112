import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { isMobile } from 'react-device-detect';
import { useDogContext } from "./DogContext.js";
import "./HundProfilImage.css";

const HundProfilImage = ({ displayShape, canMaximizeImage, renderUploadButtons, canFetch = false }) => {
	const webcamRef = useRef(null);
	const { dogContextObject, getProfilbild, cancelProfilbildRequest, setProfilbild } = useDogContext();
	const [isLoading, setIsLoading] = useState(!dogContextObject.profilbild);
	const [showModal, setShowModal] = useState(false);
	const [showWebcam, setShowWebcam] = useState(false);
	const [uploadButtons, setUploadButtons] = useState(renderUploadButtons || false);
	const [canMaximize, setCanMaximize] = useState(canMaximizeImage || false);
	const hasFetchedData = useRef(false); // Ref to track if data has been fetched

	useEffect(() => {
		const fetchProfilbild = async () => {
			if (dogContextObject.id > 0 && dogContextObject.profilbild === 'data:image/jpeg;base64,') {
				setIsLoading(true);
				try {
					await getProfilbild();
				} catch (error) {
					console.error(`Error fetching Profilbild:`, error);
				} finally {
					setIsLoading(false);
				}
			}
		};

		console.log(`mount HundeprofilImage. ${displayShape}`);
		if (canFetch && !hasFetchedData.current) {
			fetchProfilbild();
			hasFetchedData.current = true; // Set the ref to true after fetching data
		}

		return () => {
			console.log(`unmount HundeprofilImage. ${displayShape}`);
			cancelProfilbildRequest();
		};
	}, [dogContextObject.id, canFetch]);

	const handleClick = () => {
		if (!isLoading && dogContextObject.profilbild && canMaximize) {
			setShowModal(true);
		}
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleCapture = () => {
		try {
			const base64Image = webcamRef.current.getScreenshot();
			if (base64Image !== 'data:image/jpeg;base64,') {
				setProfilbild(base64Image); // Use setProfilbild instead of updateDog
			}
			setShowWebcam(false);
		} catch (error) {
			console.error("Error capturing picture:", error);
		}
	};

	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onloadend = () => {
			if (reader.result !== 'data:image/jpeg;base64,') {
				setProfilbild(reader.result); // Use setProfilbild instead of updateDog
			}
		};
		reader.readAsDataURL(file);
	};

	return (
		<>
			{!showWebcam && (
				<div className={`image-container ${displayShape}`} onClick={isLoading || !dogContextObject.profilbild ? undefined : handleClick}>
					{isLoading && <Spinner animation="border" />}
					{!isLoading && dogContextObject.profilbild && <img src={dogContextObject.profilbild} alt="" className="image" />}
					{!isLoading && !dogContextObject.profilbild && (<label className="broken-image-label"><i className="fas fa-camera"></i></label>)}
				</div>
			)}
			{uploadButtons && !showWebcam && (
				<>
					<input type="file" accept="image/jpeg" onChange={handleFileUpload} disabled={isLoading} className="image-input-file" />
					<Button variant="primary" onClick={() => setShowWebcam(true)} disabled={isLoading} className="image-webcam-button"><i className="fas fa-camera"></i></Button>
				</>
			)}
			{showWebcam && (
				<div className="webcam-container">
					<Webcam audio={false} height={720} width={1280} ref={webcamRef} screenshotFormat="image/jpeg" className="webcam" videoConstraints={{ aspectRatio: 1, facingMode: isMobile ? "environment" : "user", width: 1280, height: 720 }} />
					<div className="webcam-capture-buttongroup">
						<Button variant="success" onClick={handleCapture} disabled={isLoading}>Capture</Button>
						<Button variant="danger" onClick={() => setShowWebcam(false)} disabled={isLoading}>Cancel</Button>
					</div>
				</div>
			)}
			<Modal show={showModal} onHide={handleCloseModal} centered>
				<Modal.Body>
					{!isLoading && dogContextObject.profilbild && (
						<img
							src={dogContextObject.profilbild}
							alt=""
							className="modal-image"
						/>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default HundProfilImage;