import React, { useState, useEffect, useRef } from 'react';
import { useDogContext } from './DogContext.js'; // Import the context
import ImageWithLoading from './ImageWithLoading.js';
import { Button, Spinner } from 'react-bootstrap';
import FileUploadDialog from './FileUploadDialog.js';
import logger from '../Utils/clientLogger.mjs'
import './TabSheetHundeGalerie.css';

const TabSheetHundeGalerie = () => {
	const { dogContextObject, getGalerie, setGalerie, cancelGalerieRequest } = useDogContext(); // Use context functions
	const [editMode, setEditMode] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const hasFetchedData = useRef(false); // Ref to track if data has been fetched

	useEffect(() => {
		if (!hasFetchedData.current) {
			fetchFilesChunk();
			hasFetchedData.current = true; // Set the ref to true after fetching data
		}
		return () => {
			cancelGalerieRequest();
		};
	}, []);

	const fetchFilesChunk = async () => {
		setLoading(true);
		try {
			// Fetch the gallery data using the context function
			await getGalerie();
			await new Promise(resolve => setTimeout(resolve, 1000));
		} catch (error) {
			if (error.message !== "Request Cancelled") {
				logger.error('Error fetching gallery data:', error);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleImageClick = (datei) => {
		if (editMode) {
			setSelectedImages((prevSelected) =>
				prevSelected.some((item) => item.galerieIndex === datei.galerieIndex)
				? prevSelected.filter((item) => item.galerieIndex !== datei.galerieIndex)
				: [...prevSelected, datei]
			);
		}
	};

	const handleAddImagesClick = () => {
		setShowModal(true);
	};

	const handleEnterEditModeClick = () => {
		setEditMode(true);
		setSelectedImages([]);
	};

	const handleCancelEditModeClick = async () => {
		setEditMode(false);
		setSelectedImages([]);
	};

	const handleDeleteGalerieImageClick = async () => {
		try {
			setLoading(true);
			// Step 1: Mark selected images as visible = 0
			const updatedGalerie = dogContextObject.galerie.map((item) =>
				selectedImages.some((selectedItem) => selectedItem.galerieIndex === item.galerieIndex)
					? { ...item, visible: 0 }
					: item
			);
	
			// Step 2: Remove items where visible === 0 AND id < 0
			const filteredGalerie = updatedGalerie.filter((item) => !(item.visible === 0 && item.id < 0));
	
			// Step 3: Re-index galerieIndex sequentially
			const reindexedGalerie = filteredGalerie.map((item, index) => ({
				...item,
				galerieIndex: index
			}));
	
			// Update the gallery using setGalerie
			setGalerie(reindexedGalerie);
			await new Promise(resolve => setTimeout(resolve, 1000));
		} catch (error) {
			console.log(error);
			setSelectedImages([]);
		}
		finally{
			setSelectedImages([]);
			setLoading(false);
		}
	};

	return (
		<>
			<div className={`tabSheetHundeGalerie ${editMode ? 'edit-mode' : ''}`}>
				<div className="galerieContainer">
					{loading ? (
						<div className="tabsheet-hunde-galerie-spinnerContainer">
							<Spinner className='tabsheet-hunde-galerie-spinner' as="span" animation="border" size="sm" />
						</div>
					) : (
						dogContextObject.galerie
							.filter((datei) => datei.visible === 1)
							.map((datei) => (
								<div key={datei.galerieIndex} 
									className={`galerieImageWrapper ${editMode ? 'edit-mode' : ''} 
										${editMode && selectedImages.some((item) => item.galerieIndex === datei.galerieIndex)
											? 'selected'
											: ''
										}`
									}
								>
									<div className={`galerieoverlay ${editMode ? 'edit-mode' : ''}`} onClick={() => handleImageClick(datei)}>
										{editMode && selectedImages.some((item) => item.galerieIndex === datei.galerieIndex) && (<div className="galerieoverlay-icon">✔</div>)}
									</div>
									<ImageWithLoading
										source={datei.daten}
										dogId={dogContextObject.id}
										galerieIndex={datei.galerieIndex}
										dateiId={datei.id}
										displayShape="rectangular"
										canMaximizeImage={!editMode}
									/>
								</div>
							))
						)
					}
				</div>
			</div>
			<div className="galeriebuttonContainer">
				{editMode ? (
				<>
					<Button variant="danger" onClick={handleDeleteGalerieImageClick}>Delete</Button>
					<Button variant="primary" onClick={handleCancelEditModeClick}>Cancel</Button>
				</>
				) : (
				<>
					<Button variant="success" onClick={handleAddImagesClick} disabled={editMode}>Add</Button>
					<Button variant="primary" onClick={handleEnterEditModeClick} disabled={editMode}>Edit</Button>
				</>
				)}
			</div>
			<FileUploadDialog
				context={dogContextObject}
				updateMethod={setGalerie}
				filesPropertyName="galerie"
				showModal={showModal}
				setShowModal={setShowModal}
			/>
		</>
	);
};

export default TabSheetHundeGalerie;