import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLanguage, FaMoon, FaSun } from 'react-icons/fa'; // Import icons for theme
import './QuickBoardMenu.css';

const QuickBoardMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDarkMode = () => {
	setIsDarkMode(!isDarkMode);
  }

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  const menuItems = [
	{ label: 'Home', route: '/' },
    { label: 'Hunde', route: '/dogs' },
    { label: 'Stammbuch', route: '/stammbuch' },
    { label: 'Aktuelle Würfe', route: '/aktuellewuerfe' },
    { label: 'Boxverteilung', route: '/boxverteilung' },
  ];

  const handleButtonClick = (route) => {
    navigate(route);
    setIsOpen(false);
  };

  return (
    <div className={`QuickBoardMenu-menu ${isOpen ? 'QuickBoardMenu-menu-open' : ''}`} ref={menuRef}>
      {!isOpen && (
        <button className="QuickBoardMenu-button-toggle" onClick={toggleMenu}>
          Menu
        </button>
      )}
      <div className={`QuickBoardMenu-navigation ${isOpen ? 'QuickBoardMenu-navigation-open' : ''}`}>
        <nav>
          <div className="QuickBoardMenu-buttonGroup">
            {menuItems.map((item, index) => (
              <button
                key={index}
                onClick={() => handleButtonClick(item.route)}
                className="QuickBoardMenu-button"
              >
                {item.label}
              </button>
            ))}
            <button className="QuickBoardMenu-languagePicker"><FaLanguage /></button>
            <button className="QuickBoardMenu-darkModeToggle" onClick={toggleDarkMode}>{isDarkMode ? <FaMoon /> : <FaSun />}
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default QuickBoardMenu;