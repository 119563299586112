import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { isMobile } from 'react-device-detect';
import logger from "../Utils/clientLogger.mjs";
import { useDogContext } from "./DogContext.js";
import { mapToDateiModel } from "../ObjectModels/DateiModel.mjs";
import { cancelRequest } from "../Utils/clientCancelUtils.mjs";
import "./ImageWithLoading.css";

const ImageWithLoading = ({ source, fileIndex = 0, dateiId = -1, onImageChange, displayShape, canMaximizeImage, renderUploadButtons }) => {
	const webcamRef = useRef(null);
	const prevSrcRef = useRef();
	const { dogContextObject, updateDog } = useDogContext();
	const [src, setSrc] = useState(source || mapToDateiModel(dogContextObject.galerie[fileIndex].daten));
	const [isLoading, setIsLoading] = useState(!source);
	const [showModal, setShowModal] = useState(false);
	const [showWebcam, setShowWebcam] = useState(false);
	const [uploadButtons, setUploadButtons] = useState(renderUploadButtons || false);
	const [canMaximize, setCanMaximize] = useState(canMaximizeImage || false);
	const requestIdRef = useRef(null);

	useEffect(() => {
		return () => {
			if (requestIdRef.current) {
				cancelRequest(requestIdRef.current);
			}
		};
	}, []);

	useEffect(() => {
		const fetchDatei = async () => {
			if (!src && dateiId > -1) {
				try {
					setIsLoading(true);
					requestIdRef.current = `datei-fetch-${dateiId}`;

					const response = await axios.get(`/api/files/${dateiId}/${requestIdRef.current}`, {
						timeout: 300000,
						timeoutErrorMessage: "timeout beim datei laden"
					});

					if (response && response.data) {
						const dateiData = mapToDateiModel(response.data);
						setSrc(dateiData.daten);
					}
				} catch (error) {
					if (error.response && error.response.status === 404) {
						logger.debug(`File ${requestIdRef.current} not found in database:`, { message: error.message });
					}
					if (error.response && error.response.status === 499) {
						logger.debug(`File fetch-request ${requestIdRef.current} was cancelled on server side:`, { message: error.message });
					}
					else if (error.response) {
						logger.error(`something unexpected happened with ${requestIdRef.current}:`, { errorstack: error });
						cancelRequest(requestIdRef.current);
					}
				} finally {
					requestIdRef.current = null;
					setIsLoading(false);
				}
			}
		};

		if (!prevSrcRef.current) {
			prevSrcRef.current = src;
		}

		if (src && src !== prevSrcRef.current) {
			const updatedGalerie = [...dogContextObject.galerie];
			if (fileIndex >= 0 && fileIndex < updatedGalerie.length) {
				updatedGalerie[fileIndex].daten = src;
				const updatedDog = { ...dogContextObject, galerie: updatedGalerie };
				updateDog(updatedDog);
				if (typeof onImageChange === "function") {
					onImageChange();
				}
			}

			prevSrcRef.current = src;
			setIsLoading(false);
		}

		if (!src && dateiId>0 && !requestIdRef.current) {
			fetchDatei();
		}
	}, [src, dateiId, fileIndex, dogContextObject, onImageChange]);

	const handleClick = () => {
		if (!isLoading && src && canMaximize) {
			setShowModal(true);
		}
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleCapture = () => {
		try {
			const base64Image = webcamRef.current.getScreenshot();
			setSrc(base64Image);
			setShowWebcam(false);
		} catch (error) {
			console.error("Error capturing picture:", error);
		}
	};

	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onloadend = () => {
			setSrc(reader.result);
		};
		reader.readAsDataURL(file);
	};

	return (
		<>
			{!showWebcam && (
				<div className={`image-container ${displayShape}`} onClick={isLoading || !src ? undefined : handleClick}>
					{isLoading && <Spinner animation="border" />}
					{!isLoading && src && <img src={src} alt="" className="image" />}
					{!isLoading && !src && (
						<label className="broken-image-label">
							<i className="fas fa-camera"></i>
						</label>
					)}
				</div>
			)}
			{uploadButtons && !showWebcam && (
				<>
					<input type="file" accept="image/jpeg" onChange={handleFileUpload} disabled={isLoading} className="image-input-file" />
					<Button variant="primary" onClick={() => setShowWebcam(true)} disabled={isLoading} className="image-webcam-button"><i className="fas fa-camera"></i></Button>
				</>
			)}
			{showWebcam && (
				<div className="webcam-container">
					<Webcam audio={false} height={720} width={1280} ref={webcamRef} screenshotFormat="image/jpeg" className="webcam" videoConstraints={{ aspectRatio: 1, facingMode: isMobile ? "environment" : "user", width: 1280, height: 720 }} />
					<div className="webcam-capture-buttongroup">
						<Button variant="success" onClick={handleCapture} disabled={isLoading}>Capture</Button>
						<Button variant="danger" onClick={() => setShowWebcam(false)} disabled={isLoading}>Cancel</Button>
					</div>
				</div>
			)}
			<Modal show={showModal} onHide={handleCloseModal} centered>
				<Modal.Body>
					{!isLoading && src && (
						<img
							src={src}
							alt=""
							className="modal-image"
						/>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ImageWithLoading;