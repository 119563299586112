import React, { useState, useRef, useEffect } from 'react';
import api from '../Utils/apiAxios.js';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from 'react-bootstrap';
import DogChipScanner from './DogChipScanner.js';
import CustomerModel from '../ObjectModels/CustomerModel.mjs';
import { formatDate } from '../Utils/dateUtils.mjs';
import { cancelRequest } from '../Utils/clientCancelUtils.mjs';
import { useDogContext } from './DogContext.js';
import logger from '../Utils/clientLogger.mjs';
import './Kaufvertrag.css';

const defaultSellerValues = {
	anrede: 'Frau',
	vorname: 'Bettina',
	nachname: 'Pagels',
	land: 'Deutschland',
	strasse: 'Buchenbergweg',
	hausnummer: 18,
	plz: '74722',
	ort: 'Buchen',
	mobil: '017647669809',
	email: 'seventeen18@aol.com'
};

const Kaufvertrag = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const { dogContextObject, updateDog } = useDogContext();
	const [buyer, setBuyer] = useState({ ...CustomerModel });
	const [seller, setSeller] = useState({ ...CustomerModel, ...defaultSellerValues });
	const [errors, setErrors] = useState({});
	const [salesData, setSalesData] = useState({
		salesDate: formatDate(new Date()),
		lastSighted: formatDate(new Date()),
		price: 1500,
		specialAgreements: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [requestStatus, setRequestStatus] = useState(null);
	const [verificationStatus, setVerificationStatus] = useState({
		dogData: false,
		buyer: false,
		seller: false,
		salesData: false,
	});
	const [documents, setDocuments] = useState([]);
	const [selectedDocument, setSelectedDocument] = useState(null);
	const [pdfPreview, setPdfPreview] = useState(null);
	const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
	const requestIdRef = useRef(null);
	const [isVerifying, setIsVerifying] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const isVerified = () => Object.values(verificationStatus).every((status) => status === true);

	useEffect(() => {
		return () => {
			if (requestIdRef.current) {
				logger.error(`Kaufvertrag unmounted, cancel Kaufvertrag request`);
				cancelRequest(requestIdRef.current);
			}
		};
	}, []);

	useEffect(() => {
		const validateData = () => {
			setIsVerifying(true);
			setTimeout(() => {
				setVerificationStatus({
					dogData: validateRequiredFields(dogContextObject, ["chipnummer"]),
					salesData: validateRequiredFields(salesData, ["salesDate", "lastSighted", "price"]),
					buyer: validateRequiredFields(buyer, ["vorname", "nachname", "strasse", "hausnummer", "plz", "ort", "email"]),
					seller: validateRequiredFields(seller, ["vorname", "nachname"]),
				});
				setIsVerifying(false);
			}, 1000);
		};
		currentPage === 4 ? validateData() : setIsVerifying(false);
	}, [currentPage]);

	useEffect(() => {
		if (currentPage === 4) {
			fetchDocuments();
		}
		if (currentPage === 5 && !isSubmitting) {
			handleFormSubmit();
		}
	}, [currentPage]);

	const fetchDocuments = async () => {
		try {
			const response = await api.get("/api/system/vars/documents/docx");
			setDocuments(response.data);
		} catch (error) {
			logger.error("Error fetching documents:", error);
		}
	};

	const handleDocumentChange = async (event) => {
		event.preventDefault();
		try {
			const filepath = event.target.value;
			setSelectedDocument(filepath);
			setIsGeneratingPdf(true);
			const contractDog = { ...dogContextObject, galerie: [], profilbild: "" };
			const requestBody = {
				filepath,
				placeholderData: { dogData: contractDog, salesData, buyer, seller },
			};
			const response = await api.post(`/api/documents/generate-pdf/${uuidv4()}`, requestBody, { "Content-Type": "application/json" });
			setPdfPreview(response.data.pdfUrl);
		} catch (error) {
			logger.error("Error generating PDF:", error);
		} finally {
			setIsGeneratingPdf(false);
		}
	};

	const handlePrint = () => {
		const pdfWindow = window.open("");
		const iframe = document.createElement('iframe');
		iframe.style.width = '100%';
		iframe.style.height = '100%';
		iframe.src = pdfPreview;

		// Wait for the iframe to load before printing
		iframe.onload = () => {
			pdfWindow.document.body.appendChild(iframe);
			pdfWindow.document.close();
			pdfWindow.print();
		  };

		pdfWindow.document.write('<html><head><title>Print PDF</title></head><body><iframe width="100%" height="100%" src="' + pdfPreview + '"></iframe></body></html>');
		pdfWindow.document.close();
		pdfWindow.print();
	};

	const validateRequiredFields = (obj, requiredFields) => {
		if (!obj) return false;
		return requiredFields.every((field) => {
			const value = obj[field];
			if (typeof value === "number") return !isNaN(value) && value !== null && value !== undefined;
			if (typeof value === "string") return value.trim() !== "";
			return value !== null && value !== undefined;
		});
	};

	const validateForm = () => {
		let formErrors = {};
		if (!buyer.vorname) formErrors.vorname = "Käufername ist erforderlich";
		if (!buyer.nachname) formErrors.name = "Käufername ist erforderlich";
		if (!buyer.strasse) formErrors.name = "Straße ist erforderlich";
		if (!buyer.hausnummer) formErrors.hausnummer = "Hausnummer ist erforderlich";
		if (!buyer.plz) formErrors.name = "PLZ ist erforderlich";
		if (!buyer.ort) formErrors.name = "Ort ist erforderlich";
		if (!buyer.email) formErrors.email = "Käufer E-Mail ist erforderlich";
		if (!seller.vorname) formErrors.sellerVorname = "Verkäufer Familienname ist erforderlich";
		if (!seller.nachname) formErrors.sellerNachname = "Verkäufername ist erforderlich";
		if (!salesData.salesDate) formErrors.salesDate = "Verkaufsdatum darf nicht leer sein";
		if (!salesData.price) formErrors.price = "Ein Verkaufspreis muss angegeben werden";
		setErrors(formErrors);
		return Object.keys(formErrors).length === 0;
	};

	const handleFormSubmit = async (event) => {
		if (event) event.preventDefault();
		if (!validateForm()) {
			logger.error("Formular enthält Fehler und wird nicht gesendet:", errors);
			return;
		}
		setIsLoading(true);
		setIsSubmitting(true);
		setRequestStatus(null);
		requestIdRef.current = `kaufvertrag-${dogContextObject?.chipnummer || "unknown"}`;

		
		const requestBody ={
			dogData: dogContextObject,
			salesData,
			buyer,
			seller,
			pdfPreview,
			requestId: requestIdRef.current,
		}
		console.log('Request body size:', JSON.stringify(requestBody).length); // Log the size of the request body

		try {
			const response = await api.post("/api/sales/new", requestBody);
			if (response.data.success) {
				logger.info("Kaufvertrag erfolgreich gespeichert:", response.data.message);
	
				updateDog(response.data.updatedDog);
				setRequestStatus("success");
			} else {
				setRequestStatus("error");
			}
			setRequestStatus("success");
		} catch (error) {
			logger.error("Fehler beim Speichern des Kaufvertrags:", error);
			setRequestStatus("error");
		} finally {
			requestIdRef.current = null;
			setIsLoading(false);
			setIsSubmitting(false);
		}
	};


	return (
		<div className="Kaufvertrag-container">
		{isLoading ? (
			<div className="Kaufvertrag-loading-container">
				{requestStatus === 'success' && <div className="Kaufvertrag-success-icon">✔️</div>}
				{requestStatus === 'error' && <div className="Kaufvertrag-error-icon">❌</div>}
				{requestStatus === null && <Spinner animation="border" />}
				<div className="Kaufvertrag-button-row">
					<button onClick={() => cancelRequest(requestIdRef.current)} className="Kaufvertrag-cancel-button">Abbrechen</button>
				</div>
			</div>
		) : (
			<>
			{currentPage === 1 && (
				<form className="Kaufvertrag-form">
					<h1>Hund und Verkaufsdaten</h1>
					<DogChipScanner chipnummer={dogContextObject ? dogContextObject.chipnummer : ''}/>
					<div className="Kaufvertrag-form-group">
						<div className="Kaufvertrag-row">
							<label>Verkaufsdatum</label>
							<input type="datetime-local" value={salesData.salesDate} onChange={(e) => setSalesData({ ...salesData, salesDate: e.target.value })} required />
						</div>
						<div className="Kaufvertrag-row">
							<label>Preis</label>
							<input type="number" value={salesData.price} onChange={(e) => setSalesData({ ...salesData, price: e.target.value })} required />
						</div>
						<div className="Kaufvertrag-row">
							<label>Sondervereinbarungen</label>
							<textarea value={salesData.specialAgreements} onChange={(e) => setSalesData({ ...salesData, specialAgreements: e.target.value })} />
						</div>
					</div>
					<div className="Kaufvertrag-button-row">
						<button className="Kaufvertrag-button-next" type="button" onClick={() => setCurrentPage(2)} disabled={!dogContextObject.chipnummer>''}>Weiter</button>
					</div>
				</form>
			)}

			{currentPage === 2 && (
				<form className="Kaufvertrag-form">
					<h1>Käuferdaten</h1>
					<div className="Kaufvertrag-form-group">
						<div className="Kaufvertrag-row">
							<select value={buyer.anrede || 'Herr'} onChange={(e) => setBuyer({ ...buyer, anrede: e.target.value })}>
								<option value="Herr">Herr</option>
								<option value="Frau">Frau</option>
							</select>
							<input type="text" placeholder="Vorname" value={buyer.vorname || ''} onChange={(e) => setBuyer({ ...buyer, vorname: e.target.value })} required />
							<input type="text" placeholder="Nachname" value={buyer.nachname || ''} onChange={(e) => setBuyer({ ...buyer, nachname: e.target.value })} required />
						</div>
						<div className="Kaufvertrag-row">
							<input type="text" placeholder="Straße" value={buyer.strasse || ''} onChange={(e) => setBuyer({ ...buyer, strasse: e.target.value })} required />
							<input type="text" placeholder="Hausnummer" value={buyer.hausnummer || ''} onChange={(e) => setBuyer({ ...buyer, hausnummer: e.target.value })} required />
						</div>
						<div className="Kaufvertrag-row">
							<input type="text" placeholder="PLZ" value={buyer.plz || ''} onChange={(e) => setBuyer({ ...buyer, plz: e.target.value })} required />
							<input type="text" placeholder="Ort" value={buyer.ort || ''} onChange={(e) => setBuyer({ ...buyer, ort: e.target.value })} required />
							<input type="text" placeholder="Bundesland" value={buyer.region || ''} onChange={(e) => setBuyer({ ...buyer, region: e.target.value })} />
							<input type="text" placeholder="Land" value={buyer.land || 'Deutschland'} onChange={(e) => setBuyer({ ...buyer, land: e.target.value })} />
						</div>
						<div className="Kaufvertrag-row">
							<input type="tel" placeholder="Telefon" value={buyer.telefon || ''} onChange={(e) => setBuyer({ ...buyer, telefon: e.target.value })} />
						</div>
						<div className="Kaufvertrag-row">
							<input type="tel" placeholder="Mobil" value={buyer.mobil || ''} onChange={(e) => setBuyer({ ...buyer, mobil: e.target.value })} />
						</div>
						<div className="Kaufvertrag-row">
							<input type="email" placeholder="E-Mail" value={buyer.email || ''} onChange={(e) => setBuyer({ ...buyer, email: e.target.value })} required />
						</div>
						<div className="Kaufvertrag-row">
							<input type="text" placeholder="Zusatz 1" value={buyer.zusatz1 || ''} onChange={(e) => setBuyer({ ...buyer, zusatz1: e.target.value })} />
							<input type="text" placeholder="Zusatz 2" value={buyer.zusatz2 || ''} onChange={(e) => setBuyer({ ...buyer, zusatz2: e.target.value })} />
							<input type="text" placeholder="Zusatz 3" value={buyer.zusatz3 || ''} onChange={(e) => setBuyer({ ...buyer, zusatz3: e.target.value })} />
						</div>
					</div>
					<div className="Kaufvertrag-button-row">
						<button className="Kaufvertrag-button-prev" type="button" onClick={() => setCurrentPage(1)}>Zurück</button>
						<button className="Kaufvertrag-button-next" type="button" onClick={() => setCurrentPage(3)}>Weiter</button>
					</div>
				</form>
			)}

			{currentPage === 3 && (
				<form className="Kaufvertrag-form">
					<h1>Verkäuferdaten</h1>
					<div className="Kaufvertrag-form-group">
						<div className="Kaufvertrag-row">
							<select value={seller.anrede || 'Frau'} onChange={(e) => setSeller({ ...seller, anrede: e.target.value })}>
								<option value="Herr">Herr</option>
								<option value="Frau">Frau</option>
							</select>
							<input type="text" placeholder="Vorname" value={seller.vorname} onChange={(e) => setSeller({ ...seller, vorname: e.target.value })} required />
							<input type="text" placeholder="Nachname" value={seller.nachname} onChange={(e) => setSeller({ ...seller, nachname: e.target.value })} required />
						</div>
						<div className="Kaufvertrag-row">
							<input type="text" placeholder="Straße" value={seller.strasse || 'Buchenbergweg'} onChange={(e) => setSeller({ ...seller, strasse: e.target.value })} />
							<input type="text" placeholder="Hausnummer" value={seller.hausnummer || '18'} onChange={(e) => setSeller({ ...seller, hausnummer: e.target.value })} />
						</div>
						<div className="Kaufvertrag-row">
							<input type="text" placeholder="PLZ" value={seller.plz || '74722'} onChange={(e) => setSeller({ ...seller, plz: e.target.value })} />
							<input type="text" placeholder="Ort" value={seller.ort || 'Buchen'} onChange={(e) => setSeller({ ...seller, ort: e.target.value })} />
							<input type="text" placeholder="Bundesland" value={seller.region || 'Baden-Württemberg'} onChange={(e) => setSeller({ ...seller, region: e.target.value })} />
							<input type="text" placeholder="Land" value={seller.land || 'Deutschland'} onChange={(e) => setSeller({ ...seller, land: e.target.value })} />
						</div>
						<div className="Kaufvertrag-row">
							<input type="tel" placeholder="Telefon" value={seller.telefon || ''} onChange={(e) => setSeller({ ...seller, telefon: e.target.value })} />
						</div>
						<div className="Kaufvertrag-row">
							<input type="tel" placeholder="Mobil" value={seller.mobil || '017647669809'} onChange={(e) => setSeller({ ...seller, mobil: e.target.value })} />
						</div>
						<div className="Kaufvertrag-row">
							<input type="email" placeholder="E-Mail" value={seller.email || 'seventeen18@aol.com'} onChange={(e) => setSeller({ ...seller, email: e.target.value })} />
						</div>
						<div className="Kaufvertrag-row">
							<input type="text" placeholder="Zusatz 1" value={seller.zusatz1 || ''} onChange={(e) => setSeller({ ...seller, zusatz1: e.target.value })} />
							<input type="text" placeholder="Zusatz 2" value={seller.zusatz2 || ''} onChange={(e) => setSeller({ ...seller, zusatz2: e.target.value })} />
							<input type="text" placeholder="Zusatz 3" value={seller.zusatz3 || ''} onChange={(e) => setSeller({ ...seller, zusatz3: e.target.value })} />
						</div>
					</div>
					<div className="Kaufvertrag-button-row">
						<button className="Kaufvertrag-button-prev" type="button" onClick={() => setCurrentPage(2)}>Zurück</button>
						<button className="Kaufvertrag-button-next" type="button" onClick={() => setCurrentPage(4)}>Weiter</button>
					</div>
				</form>
			)}

			{currentPage === 4 && (
				<div className="Kaufvertrag-verification-page">
					{isVerifying && !isVerified() ? (
						<>
							<h1>Datenprüfung</h1>
							{[
								{ label: 'Hundedaten', statusKey: 'dogData' },
								{ label: 'Verkaufsdaten', statusKey: 'salesData' },
								{ label: 'Käuferdaten', statusKey: 'buyer' },
								{ label: 'Verkäuferdaten', statusKey: 'seller' }
							].map((item, index) => (
								<div key={index} className="Kaufvertrag-verification-item">
									<span>{item.label}</span>
									{isVerifying 
										? (<Spinner animation="border" />) 
										: verificationStatus[item.statusKey] 
											? (<span className="Kaufvertrag-success-icon">✔️</span>) 
											: (<span className="Kaufvertrag-error-icon">❌</span>)
									}
								</div>
							))}
						</>
					) : isVerified() ? (
						<div className="Kaufvertrag-pdf-preview-container">
							<div className="Kaufvertrag-form-group">
								<select onChange={handleDocumentChange} value={selectedDocument || ''}>
									<option value="">Dokument auswählen</option>
									{documents.map((doc, index) => (<option key={index} value={doc.filepath + '/' + doc.filename + doc.extension}>{doc.filename}</option>))}
								</select>
							</div>
							<div className="Kaufvertrag-pdf-preview">
								{isGeneratingPdf ? (
									<div className="Kaufvertrag-loading-pdf">
										<span>PDF wird generiert...</span>
										<Spinner animation="border" />
									</div>
								) : pdfPreview && (
									<iframe src={pdfPreview} width="100%" height="800px" title="PDF Vorschau"></iframe>
								)}
							</div>
							<div className="Kaufvertrag-button-row">
								<button className="Kaufvertrag-button-prev" type="button" onClick={() => setCurrentPage(3)}>Zurück</button>
								<button className="Kaufvertrag-button-submit" type="button" onClick={() => setCurrentPage(5)} disabled={!pdfPreview}>Abschicken</button>
								<button className="Kaufvertrag-print-button" type="button" onClick={handlePrint} disabled={!pdfPreview}>Drucken</button>
							</div>
						</div>
					) : (
						<div className="Kaufvertrag-verification-error">
							<span>❌ Einige Daten sind ungültig. Bitte überprüfen Sie die Eingaben.</span>
							<div className="Kaufvertrag-button-row">
								<button className="Kaufvertrag-button-prev" type="button" onClick={() => setCurrentPage(3)}>Zurück</button>
							</div>
						</div>
					)}
				</div>
			)}

			{currentPage === 5 && (
				<div className="Kaufvertrag-submission-page">
					<div className="Kaufvertrag-form-group">
						{requestStatus === null && "Vertrag wird an den Server geschickt..."}
						{requestStatus === 'success' && "Vertrag erfolgreich gesendet!"}
						{requestStatus === 'error' && "Fehler beim Senden des Vertrags."}
						
						{requestStatus === null && <Spinner animation="border" />}
						{requestStatus === 'success' && <span className="Kaufvertrag-success-icon">✔️</span>}
						{requestStatus === 'error' && <span className="Kaufvertrag-error-icon">❌</span>}
					</div>
					<div className="Kaufvertrag-pdf-preview-container">
						{pdfPreview && ( <iframe src={pdfPreview} width="100%" height="800px" title="PDF Vorschau"></iframe> )}
					</div>
					<div className="Kaufvertrag-button-row">
						<button className="Kaufvertrag-button-prev" type="button" onClick={() => setCurrentPage(4)} disabled={requestIdRef.current != null}>Zurück</button>
						{
							requestStatus === null
							? (<button className="Kaufvertrag-cancel-button" type="button" onClick={() => cancelRequest(requestIdRef.current)}>Abbrechen</button>)
							: (<button className="Kaufvertrag-resend-button" type="button" onClick={handleFormSubmit} disabled={requestIdRef.current != null}>Neu senden</button>)
						}
						<button className="Kaufvertrag-print-button" type="button" onClick={handlePrint} disabled={!pdfPreview}>Drucken</button>
					</div>
				</div>
			)}
			</>
		)}
		</div>
	);
};

export default Kaufvertrag;