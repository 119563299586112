import { formatDate } from '../Utils/dateUtils.mjs';
import { convertBinaryToBase64 } from '../Utils/base64Utils.mjs'

const currentDate = new Date();
class DogModel {
	constructor(data = {}) {
		this.id = data.id || -1;
		this.profilbild = data.profilbild ? data.profilbild : convertBinaryToBase64([]);
		this.hundname = data.hundname || "";
		this.kennelname = data.kennelname || "";
		this.geburtsdatum = data.geburtsdatum || formatDate(currentDate);
		this.chipnummer = data.chipnummer || "";
		this.farbe = data.farbe || "";
		this.statur = data.statur || "";
		this.augenfarbelinks = data.augenfarbelinks || "";
		this.augenfarberechts = data.augenfarberechts || "";
		this.mutter = data.mutter;
		this.mutterId = data.mutterId || -1;
		this.vater = data.vater;
		this.vaterId = data.vaterId || -1;
		this.besitzer = data.besitzer;
		this.besitzerId = data.besitzerId || -1;
		this.visible = data.visible || 1;
		this.istruede = data.istruede;
		this.adatum = formatDate(currentDate);
		this.edatum = data.edatum || formatDate(currentDate);
		this.wurfnummer = data.wurfnummer || "";
		this.stammhund = data.stammhund || "";
		this.registrierterName = data.registrierterName || "";
		this.zuchtbuchnummer = data.zuchtbuchnummer || "";
		this.status = data.status || "";
		this.rasse = data.rasse || "";
		this.registrierteInstanz = data.registrierteInstanz || "";
		this.trimfarbe = data.trimfarbe || "";
		this.rute = data.rute || "";
		this.impfungen = data.impfungen || [];
		this.lastta = data.lastta || "";
		this.nextta = data.nextta || "";
		this.galerie = data.galerie || [];
	}

	get alter() {
		const birthDate = new Date(this.geburtsdatum);
		const currentDate = new Date();
		const ageInMilliseconds = currentDate - birthDate;
		const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
		return ageInYears.toFixed(2);
	}
}

export const mapToDogModel = (dog) => {
	// Define transformations for specific fields
	const fieldTransformations = {
		geburtsdatum: (value) => new Date(value).toISOString().split('T')[0], // Format date
		adatum: (value) => new Date(value).toISOString(), // Format date
		edatum: (value) => new Date(value).toISOString(), // Format date
		lastta: (value) => new Date(value).toISOString().split('T')[0], // Format date
		nextta: (value) => new Date(value).toISOString().split('T')[0], // Format date
		impfungen: (value) => (typeof value === 'string' ? value.split(",") : value), // Split string into array
		profilbild: (value) => convertBinaryToBase64(value) //`data:image/jpeg;base64,${!value ? '' : Buffer.from(value).toString('base64')}`
	};

	// Create a case-insensitive lookup for the input object
	const caseInsensitiveDog = {};
	for (const key in dog) {
		caseInsensitiveDog[key.toLowerCase()] = dog[key];
	}

	// Create a new DogModel instance to get the default properties
	const defaultDog = new DogModel();

	// Map the input object to the DogModel properties
	const mappedDog = Object.keys(defaultDog).reduce((acc, key) => {
		// Lookup the value in the case-insensitive object
		const value = caseInsensitiveDog[key.toLowerCase()];

		// Apply transformation if defined, otherwise use the value as-is
		acc[key] = fieldTransformations[key] ? fieldTransformations[key](value) : value;

		// If the value is undefined, use the default value from DogModel
		if (acc[key] === undefined) {
			acc[key] = defaultDog[key];
		}

		return acc;
	}, {});

	return new DogModel(mappedDog);
};


export default DogModel;
