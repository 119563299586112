import React from "react";
import './../fontawesome/css/all.min.css'
import './GenderSwitch.css'

const GenderSwitch = ({ label, istruede, onChange }) => {
    const toggleGender = () => {
        // eslint-disable-next-line no-restricted-globals
        onChange({ target: { name: "istruede", value: !istruede } });
    };

  return (
    <div className="form-switch">
      <label>{label}:</label>
      <div>
        <input
          type="checkbox"
          className="form-check-input"
          checked={istruede}
          onChange={toggleGender}
        />
        <i className={`gender-icon fas fa-${istruede ? "mars male" : "venus female"}`}></i>
      </div>
    </div>
  );
};

export default GenderSwitch;
