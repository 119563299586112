import axios from "axios";
import logger from "./clientLogger.mjs";

export const checkCancellation = (requestId, cleanup, onCancel) => {
	const interval = setInterval(async () => {
		if (requestId) {
			const isCancelledResult = await isCancelled(requestId);
			if (isCancelledResult){
				clearInterval(interval);
				if (cleanup) { cleanup() };
				if (onCancel) { onCancel(new Error(`Request ${requestId} Cancelled`))};
			}
		}
	}, 1000);

	return interval;
};

export const cancelRequest = async (requestId) => {		
	if (requestId) {
		try {	
			const result = await axios.post(`/api/tokens/${requestId}/cancelRequest`); 
			if (result.status === 200) logger.info(`request: ${requestId} is canceled`);
		}
		catch (error) {
			if (error.response && error.response.status === 404) {
				logger.debug(`request: ${requestId} not found and not cancelable`, { message: error.message });
			}
			else if (error.response) {
				logger.error(`something unexpected happened with cancelRequest of: ${requestId}:`, { errorstack: error });
			}
		}
	}
};

async function isCancelled(requestId) {
	try {
		if (requestId) {
			const result = await axios.get(`/api/tokens/${requestId}/status`);
			return result.data;
		}
		return false;
	} catch (error) {
		logger.error('Error requesting CancelToken Status:', {requestId: requestId, errorstack: error});
	}
};
