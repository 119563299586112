/**
 * Formatiert ein Datum in das Format "YYYY-MM-DD HH:MM".
 * @param {Date} date - Das zu formatierende Datum.
 * @returns {string} Formatiertes Datum.
 */

// Aktuelles Datum im richtigen Format
export const formatDate = (date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const formatDateFirebird = (date) => {
    if (!date) return null; // Handle null or undefined values

    if (typeof date === 'string') {
        date = new Date(date.replace(" ", "T"));  // 🔹 Convert 'YYYY-MM-DD HH:MM' to Date
    }

    if (!(date instanceof Date) || isNaN(date.getTime())) {
        return null; // Return null if the conversion fails
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0'); // 🔹 Include seconds

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};