import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import InfiniteScroll from 'react-infinite-scroll-component';
import logger from '../Utils/clientLogger.mjs';
import { useTheme } from './ThemeContext.js';
import { cancelRequest } from '../Utils/clientCancelUtils.mjs';
import HundProfilePanelProvider from './HundProfilePanelProvider.js';
import './Hunde.css';

const InfiniteDogList = ({ endpoint }) => {
	const { isDarkMode } = useTheme();
	const [dogs, setDogs] = useState([]);
	const [filteredDogs, setFilteredDogs] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const dogRequest = useRef(null);
	const limit = 10;
	
	const fetchData = useCallback(async (reset = false) => {
		setIsLoading(true);
		try {
			const newOffset = reset ? 0 : offset;

			dogRequest.current = `fetch-all-dogs-${uuidv4()}`;
			const response = await axios.get(`${endpoint}/${newOffset}/${limit}/${dogRequest.current}`);
			
			setDogs(prev => reset ? response.data : [...prev, ...response.data]);
			setOffset(newOffset + limit);
			setHasMore(response.data.length >= limit);
		} catch (error) {
			if (error.response && error.response.status === 404) {
				logger.debug(`Dogs not found in database:`, { message: error.message });
			}
			if (error.response && error.response.status === 499) {
				logger.debug(`Dog fetch-request ${dogRequest.current} was cancelled on server side:`, { message: error.message });
			}
			else if (error.response) {
				logger.error(`something unexpected happened with ${dogRequest.current}:`, { errorstack: error });
				cancelRequest();
			}
			setError(error);
		} finally {
			dogRequest.current =null
			setIsLoading(false);
		}
	}, [offset, endpoint]);

	const handleRefresh = () => {
		cancelRequest(dogRequest.current);
		dogRequest.current = null;
		setOffset(0);
		setHasMore(true);
		fetchData(true);
	};

	const handleFilter = useCallback((text) => {
		if (!text) {
			setFilteredDogs(dogs);
			return;
		}
		
		const lowerText = text.toLowerCase();
		const filtered = dogs.filter(dog => Object.values(dog).some(value => String(value).toLowerCase().includes(lowerText)));
		setFilteredDogs(filtered);
	}, [dogs]);

	useEffect(() => {
		handleFilter(filterText);
	}, [filterText, handleFilter]);

	useEffect(() => {
		fetchData(true);
		return () => cancelRequest(dogRequest.current);
	}, [endpoint]);

	return (
		<div className={`InfiniteDogList-Container ${isDarkMode ? 'dark' : ''}`}>
			<div className="InfiniteDogList-filter-controls">
				<input type="text" placeholder="Filter dogs..." value={filterText} onChange={(e) => {setFilterText(e.target.value)}} />
				<button onClick={handleRefresh} disabled={isLoading}>
					{isLoading ? <Spinner /> : <RefreshIcon />}
				</button>
			</div>

			{error && <div className="InfiniteDogList-error-message">Error: {error.message}</div>}

			<InfiniteScroll
				dataLength={filteredDogs.length}
				next={fetchData}
				hasMore={hasMore}
				loader={<Spinner />}
				endMessage={<p>No more dogs to show</p>}
				scrollableTarget="scrollable-div"
			>
				<div id="scrollable-div" style={{ height: '80vh', overflow: 'auto' }}>
					{filteredDogs.map((dog) => (<HundProfilePanelProvider key={dog.id} dogData={dog} />))}
				</div>
			</InfiniteScroll>
		</div>
	);
};

// Default component with initial configuration
const Hunde = () => (
	<InfiniteDogList endpoint='api/dogs'/>
);

export default Hunde;

// Helper components
const Spinner = () => <div className="spinner">Loading...</div>;
const RefreshIcon = () => <i className="refresh-icon">⟳</i>;