import { formatDate } from '../Utils/dateUtils.mjs';

const currentDate = new Date();

const CustomerModel = {
    id: -1,
    visible: true,
    status: "active",
    anrede: "",
    vorname: "",
    nachname: "",
    strasse: "",
    hausnummer: "",
    plz: "",
    ort: "",
    region: "",
    land: "",
    telefon: "",
    mobil: "",
    email: "",
    zusatz1: "",
    zusatz2: "",
    zusatz3: "",
    adatum: formatDate(currentDate),
    edatum: formatDate(currentDate)
};


export const mapToCustomerModel = (customer) => {
    return Object.keys(CustomerModel).reduce((mappedCustomer, key) => {
        mappedCustomer[key] = customer[key.toUpperCase()] || CustomerModel[key];
        return mappedCustomer;
    }, {});
};

export default CustomerModel;