import React, { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Webcam from 'react-webcam';
import { useDropzone } from 'react-dropzone';
import { isMobile } from 'react-device-detect';
import { formatDate } from '../Utils/dateUtils.mjs';
import DateiModel from '../ObjectModels/DateiModel.mjs';
import mime from 'mime'; // Import mime-types library
import './FileUploadDialog.css';

const baseGalerieObject = {
	...DateiModel,
	galerieIndex: 0
};

const FileUploadDialog = ({ context, updateMethod, filesPropertyName, showModal, setShowModal }) => {
	const [webcamEnabled, setWebcamEnabled] = useState(false);
	const [capturedFiles, setCapturedFiles] = useState([]); // Renamed from capturedImages
	const webcamRef = useRef(null);

	const saveChanges = () => {
		try {
			let fileIndexCounter = context[filesPropertyName].length || 0;

			const newFiles = capturedFiles.map(file => {
				// Extract MIME type and extension
				const mimeType = file.split(';')[0].split(':')[1];
				const extension = mime.getExtension(mimeType) || '';

				return {
					...baseGalerieObject,
					daten: file,
					dateiname: `file_${new Date().getTime()}_${fileIndexCounter+1}`,
					extension: `.${extension}`,
					adatum: formatDate(new Date()),
					edatum: formatDate(new Date()),
					galerieIndex: fileIndexCounter++
				};
			});

			const updatedFiles = [...context[filesPropertyName], ...newFiles];
			updateMethod(updatedFiles);
			setCapturedFiles([]);
			setWebcamEnabled(false);
			setShowModal(false);
			
		} catch (error) {
			console.log(error.message)
		}
	};

	const onDrop = acceptedFiles => {
		const promises = acceptedFiles.map(file => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			return new Promise(resolve => {
				reader.onload = () => {
					resolve(reader.result);
				};
			});
		});
		Promise.all(promises).then(fileDataUrls => {
			setCapturedFiles(prevFiles => [...prevFiles, ...fileDataUrls]);
		});
	};

	const onSnapPicture = () => {
		try {
			const base64Image = webcamRef.current.getScreenshot();
			setCapturedFiles(prevFiles => [...prevFiles, base64Image]);
		} catch (error) {
			console.error('Error capturing picture:', error);
		}
	};

	const handleFileClick = index => {
		const updatedFiles = capturedFiles.filter((_, i) => i !== index);
		setCapturedFiles(updatedFiles);
	};

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	return (
		<Modal show={showModal} onHide={() => setShowModal(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Add Files</Modal.Title>
			</Modal.Header>
			<Modal.Body className='FileUploadDialogBody'>
				<div {...getRootProps()} className="dropzone">
					<input {...getInputProps()} />
					<p>Drag 'n' drop some files here, or click to select files</p>
				</div>
				<Button onClick={() => setWebcamEnabled(!webcamEnabled)} className='FileUploadCameraButton'>
					{webcamEnabled ? 'Disable Webcam' : 'Enable Webcam'}
				</Button>
				{webcamEnabled && (
					<>
						<Webcam
							audio={false}
							ref={webcamRef}
							screenshotFormat="image/jpeg"
							className="webcam"
							videoConstraints={{ aspectRatio: 1, facingMode: isMobile ? "environment" : "user", width: "1280px", height: "720px" }}
						/>
						<div>
							<Button variant="success" onClick={onSnapPicture}>Snap picture!</Button>
						</div>
					</>
				)}
				<div className="capturedFilesContainer">
					{capturedFiles.map((file, index) => (
						<div key={index} className="fileContainer">
							{file.startsWith('data:image') ? (
								<img src={file} alt={`captured-${index}`} onClick={() => handleFileClick(index)} />
							) : (
								<div className="filePreview" onClick={() => handleFileClick(index)}>
									📄 File {index + 1}
								</div>
							)}
							<div className="fileOverlay" onClick={() => handleFileClick(index)}>
								X
							</div>
						</div>
					))}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => { setCapturedFiles([]); setWebcamEnabled(false); setShowModal(false); }}>Close</Button>
				<Button variant="success" onClick={saveChanges}>Save Changes</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default FileUploadDialog;