import React, { useState, useEffect } from "react";
import { useDogContext } from './DogContext';
import { Accordion, Button, Tab, Tabs } from "react-bootstrap";
import TabsheetHundeProfil from "./TabsheetHundeProfil.js";
import TabSheetHundeGalerie from "./TabSheetHundeGalerie.js";
import HundProfilImage from "./HundProfilImage.js";
import { FaBirthdayCake, FaVenusMars, FaPaw, FaCalendarAlt, FaUser, FaIdCard } from "react-icons/fa";
import "./HundProfilePanel.css";
import logger from "../Utils/clientLogger.mjs";

const HundProfilePanel = ({ tabsheet = "profil", onDogDeleted }) => {
	const { dogContextObject, updateDogInDb, refreshDog, removeDog } = useDogContext();
	const [NewProfileImageLoading, setNewProfileImageLoading] = useState(false);
	const [activeTabsheet, setActiveTabsheet] = useState(tabsheet);

	useEffect(() => {
		setActiveTabsheet(tabsheet);
	}, [tabsheet]);

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString("de-DE", { day: "numeric", month: "long", year: "numeric" });
	};

	const handleDbUpdate = async () => {
		setNewProfileImageLoading(true);
		await updateDogInDb(dogContextObject); // Use DogContext's updateDog
		setTimeout(() => {
			setNewProfileImageLoading(false);
		}, 1000);
	};

	const handleDbRefresh = async () => {
		try {
			setNewProfileImageLoading(true);
			const refreshedDog = await refreshDog(dogContextObject.id); // Use DogContext's reloadDogFromDatabase
			logger.debug("Cancel/Refresh response:", refreshedDog);
			setTimeout(() => {
				setNewProfileImageLoading(false);
			}, 1000);
		} catch (error) {
			console.error("Error DB Refresh:", error);
		}
	};

	const handleDbDelete = async () => {
		setNewProfileImageLoading(true);
		await removeDog(dogContextObject.id); // Use DogContext's deleteDogInDatabase
		if (onDogDeleted) {
			onDogDeleted();
		}
		setTimeout(() => {
			setNewProfileImageLoading(false);
		}, 1000);
	};

	const handleTabChange = (key) => {
		setActiveTabsheet(key);
	};

	return (
		<Accordion className="hund-profile-panel">
			<Accordion.Item eventKey="0">
				<Accordion.Header>
					<div className="header-grid-container">
						<div className="header-grid-cell-profilbild">
							{!NewProfileImageLoading && (
								<HundProfilImage
									canMaximizeImage={false}
									renderUploadButtons={false}
									canFetch={false}
									key={dogContextObject.id}
								/>
							)}
						</div>
						<div className="header-grid-cell-data">
							<div className="data-item">
								<FaPaw />
								<span>Alter:</span>
								<span>{dogContextObject.alter} Jahre</span>
							</div>
							<div className="data-item">
								<FaUser />
								<span>Name:</span>
								<span>{dogContextObject.hundname}</span>
							</div>
							<div className="data-item">
								<FaCalendarAlt />
								<span>Geboren am:</span>
								<span>{formatDate(dogContextObject.geburtsdatum)}</span>
							</div>
							<div className="data-item">
								<FaVenusMars />
								<span>Geschlecht:</span>
								<span>{dogContextObject.istruede ? "Rüde" : "Hündin"}</span>
							</div>
							<div className="data-item">
								<FaIdCard />
								<span>Status:</span>
								<span>{dogContextObject.status}</span>
							</div>
						</div>
					</div>
				</Accordion.Header>
				<Accordion.Collapse eventKey="0">
				<>
					<Tabs defaultActiveKey={tabsheet} id="hunde-profile-tabs" onSelect={handleTabChange} activeKey={activeTabsheet}>
						<Tab eventKey="profil" title="Profil">
							{activeTabsheet === "profil" && <TabsheetHundeProfil profilbild={dogContextObject.profilbild} />}
						</Tab>
						<Tab eventKey="welpen" title="Welpen">
							{activeTabsheet === "welpen" && <div>{activeTabsheet}</div>}
						</Tab>
						<Tab eventKey="galerie" title="Galerie">
							{activeTabsheet === "galerie" && <TabSheetHundeGalerie />}
						</Tab>
					</Tabs>
					<div className="footer-area">
						<Button variant="danger" onClick={handleDbDelete} disabled={dogContextObject.id<0}>Löschen</Button>
						<Button variant="secondary" onClick={handleDbRefresh}>Neu laden</Button>
						<Button variant="success" onClick={handleDbUpdate}>Speichern</Button>
					</div>
				</>
				</Accordion.Collapse>
			</Accordion.Item>
		</Accordion>
	);
};

export default HundProfilePanel;