export const extractBase64Data = (base64String) => {
    if (!base64String) return null; // Handle null or empty input
    return base64String.split(",")[1] || null;
};

export const convertBase64ToBinary = (base64String) => {
    const cleanBase64 = extractBase64Data(base64String);
    return cleanBase64 ? Buffer.from(cleanBase64, 'base64') : null;
};

export const convertBinaryToBase64 = (binaryData, mimeType = "image/jpeg") => {
    return binaryData ? `data:${mimeType};base64,${binaryData.toString('base64')}` : null;
};